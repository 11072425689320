import React from 'react';
import ReactTable from "react-table";
import { Row, Col, Card, CardBody, CardHeader, CardFooter, CardTitle, Button } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

export const ParticipantEncounters = props => {
  const pid = parseInt(props.match.params.pid);
  const id = parseInt(props.match.params.id);
  const storedEncounters = JSON.parse(window.localStorage.getItem('participantEncounters'));
  const index = storedEncounters.findIndex(item => item.key === id);
  const encounters = storedEncounters[index];
  encounters.data.sort((a, b) => {return a.order < b.order ? -1 : 1});
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row className="justify-content-center">
          <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Encounter Details for Participant {encounters.participant}<br />(Day:{encounters.dayNumber} - Event:{encounters.eventNumber})
              </CardTitle>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={encounters.data}
                sortable={false}
                columns={[
                  {
                    Header: "Question",
                    accessor: "question",
                    width: 500
                  },
                  {
                    Header: "Symptom",
                    accessor: "symptom",
                    width: 180
                  },
                  {
                    Header: "Answer",
                    accessor: "answer",
                    width:75,
                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                  }
                ]}
                pageSize={encounters.data ? encounters.data.length : 5}
                showPagination={false}
                className="-striped"
              />
            </CardBody>
            <CardFooter className="pull-right">
              <Button className="btn-fill" color="danger" type="submit"
                onClick={() => props.history.push(`/admin/participants/${pid}/summary`)}>
                Go Back
              </Button>
            </CardFooter>
          </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
