
/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";


/* {1900 + new Date().getYear()} instead of 2021 */
class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid}>
          <div className="copyright">
            &copy; {1900 + new Date().getYear()}. Built by {" "}
            <a
              href="https://www.affigo.io/"
              target="_blank"
            >
              Affigo
            </a>
            .
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.defaultProps = {
  default: false,
  fluid: false
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
