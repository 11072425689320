/*eslint-disable*/
import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import { isStudyStarted } from '../../helpers';
import { wallpapersActions } from '../../actions';
import { wallpapersService } from '../../services';

class Wallpapers extends Component {
  constructor(props) {
    super(props);
    this.props.getAll();
    this.state = {
      data: []
    };
  }

  render() {
    var { wallpapers } = this.props;
    var image64 = null;

    if (wallpapers && wallpapers.length > 0) {
      var dataItems = wallpapers.map((el) => {

        var actions = <div className="actions-right">
                <Button
                    onClick={() => {
                    askConfirmationDelete(el.id);
                    }}
                    className="btn-icon btn-round"
                    color="danger"
                    size="sm">
                    <i className="fa fa-trash" />
                </Button>
                {"  "}
                <Button
                    onClick={() => {
                    this.props.history.push({
                        pathname: `/admin/wallpapers/${el.id}/edit`,
                        state: { theWallpaper: el }
                    });
                    }}
                    className="btn-icon btn-round"
                    color="warning"
                    size="sm">
                    <i className="fa fa-edit" />
                </Button>
            </div>;
        
        if(isStudyStarted()){
          actions = <Button
                    onClick={() => {
                    this.props.history.push({
                        pathname: `/admin/wallpapers/${el.id}/edit`,
                        state: { theWallpaper: el }
                    });
                    }}
                    className="btn-icon btn-round"
                    color="warning"
                    size="sm">
                    <i className="fa fa-edit" />
                </Button>;
        }

        return {
          id: el.id,
          title: el.title,

          actions: actions
        };
      });
    }

    const deleteWallpaper =(id) => {
      wallpapersService.deleteWallpaper(id).then(r => {
          if(r){
            this.setState({ alert: null })

            dataItems = dataItems.filter(function( obj ) {
              return obj.id !== id;
            });
  
            this.props.wallpapers = this.props.wallpapers.filter(function( obj ) {
                return obj.id !== id;
            });

            wallpapers = wallpapers.filter(function( obj ) {
              return obj.id !== id;
            });
  
            this.setState({data: this.state});
          } else {
            this.setState({
                alert: (
                    <SweetAlert
                        danger
                        style={{ display: "block", marginTop: "100px" }}
                        title="There was an error trying to delete the wallpaper"
                        onConfirm={() => this.setState({ alert: null })}
                        confirmBtnBsStyle="info"
                    >
                    </SweetAlert>
                )
            });
          }
      })
    }
  
    const askConfirmationDelete =(id) => {
        this.setState({ alert: null })
        this.setState({
            alert: (
              <SweetAlert
                danger
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                style={{ display: "block", marginTop: "100px" }}
                onConfirm={() => deleteWallpaper(id)}
                onCancel={() => this.setState({ alert: null })}
                //focusCancelBtn
              >
                You will not be able to recover this Wallpaper!
              </SweetAlert>
            )
        });
    }

    var buttonAdd = <Button color="info" className="btn-round btn-icon pull-right"
                    onClick={() => {
                      this.props.history.push("/admin/wallpapers/0/edit");
                    }}>
                    <i className="now-ui-icons ui-1_simple-add" />
                  </Button>;

    if(isStudyStarted()){
      buttonAdd = "";
    }

    return (
      <>
        {this.state.alert}
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  {buttonAdd}
                  <CardTitle tag="h4">Wallpapers</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={dataItems}
                    filterable
                    columns={[
                      {
                        Header: "Wallpaper",
                        accessor: "title",
                        minWidth: 500
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        maxWidth: 150 
                      }
                    ]}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

      </>
    );
  }
}

function mapState(state) {
  const { wallpapers } = state.wallpapers;
  return { wallpapers };
}

const actionCreators = {
  getAll: wallpapersActions.getAll
};

const connectedWallpapers = connect(mapState, actionCreators)(Wallpapers);
export { connectedWallpapers as Wallpapers }; 