import { genericConstants } from '../constants';
import { conceptsService} from '../services'; 
import { alertActions } from '.';

export const conceptsActions = {
    getAll
};

function getAll() {
    return dispatch => {
        dispatch(request());

        conceptsService.getAllConcepts()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.CONCEPTS_REQUEST } }
    function success(items) { return { type: genericConstants.CONCEPTS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.CONCEPTS_FAILURE, error } }
}