import { genericConstants } from '../constants';
import { overviewstatsService} from '../services'; 
import { alertActions } from '.';

export const overviewstatsActions = {
    getAll,
    getAllMy
};

function getAll() {
    return dispatch => {
        dispatch(request());

        overviewstatsService.getAllStats()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.OVERVIEWSTATS_REQUEST } }
    function success(items) { return { type: genericConstants.OVERVIEWSTATS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.OVERVIEWSTATS_FAILURE, error } }
}

function getAllMy(pId) {
    return dispatch => {
        dispatch(request());

        overviewstatsService.getMyStats(pId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.OVERVIEWSTATS_REQUEST } }
    function success(items) { return { type: genericConstants.OVERVIEWSTATS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.OVERVIEWSTATS_FAILURE, error } }
}