import { installService } from '../services';

export function isConfigReady(ready) {
    var isReady = false;
    if (ready === undefined) {
        try{
            isReady = JSON.parse(localStorage.getItem('install_configReady'));
            if(isReady===null){
                updateInstallStatus();
                isReady = true;
            }
        } catch(e) {
            updateInstallStatus();
            isReady = true;
        }
    } else {
        isReady = ready;
        window.localStorage.setItem("install_configReady",JSON.stringify(ready));
        var installStatus = {
                "id": 1,
                "configReady": ready,
                "studyStarted": false
            }
        installService.saveInstallStatus(installStatus);
    }

    //console.log("isConfigReady: ", isReady);
    return isReady;
}

export function isStudyStarted(started) {
    var isStarted = false;
    if (started === undefined) {
        try{
            isStarted = JSON.parse(localStorage.getItem('install_studyStarted'));
            if(isStarted===null){
                updateInstallStatus();
                isStarted = false;
            }
        } catch(e) {
                updateInstallStatus();
                isStarted = false;
        }
    } else {
        console.log("isStudyStarted started: ", started);
        isStarted = started;
        window.localStorage.setItem("install_studyStarted",JSON.stringify(started));
        var installStatus = {
				"id": 1,
				"configReady": true,
				"studyStarted": started
			}
        installService.saveInstallStatus(installStatus);
    }

    console.log("isStudyStarted: ", isStarted);
    return isStarted;
}

export function configNotReadyErrors() {
    var configNotReadyErrors = "";
    try{
        var tConfigNotReadyErrors = JSON.parse(localStorage.getItem('install_configNotReadyErrors'));
        if(tConfigNotReadyErrors!=null && tConfigNotReadyErrors.length > 0){
            configNotReadyErrors = tConfigNotReadyErrors;
        }
    } catch(e) {
        console.log("Error retrieving configNotReadyErrors: ", e.error);
    }

    return configNotReadyErrors;
}

export function updateInstallStatus() {
    // Swap these comments for production 
    /* JLIZASO CHANGE: DEMO 
    installService.getInstallStatus().then( // Gets the values from Database without reevaluating status
        response => {
            var installResponse = response;
            window.localStorage.setItem("install_configReady",JSON.stringify(installResponse.configReady));
            window.localStorage.setItem("install_studyStarted",JSON.stringify(installResponse.studyStarted));
            window.localStorage.setItem("install_configNotReadyErrors",JSON.stringify(installResponse.errors));
            //console.log("updateInstallStatus: ", installResponse);
            //console.log("updateInstallStatus configReady: ", installResponse.configReady);
            //console.log("updateInstallStatus studyStarted: ", installResponse.studyStarted);
        },
        error => {
            console.log("updateInstallStatus error",error.toString());
        }
    );*/
    
    /* JLIZASO CHANGE: PRODUCTION */
    installService.recheckInstallStatus().then( // Reevaluates status, save them to the database, and bring back results
        response => {
            var installResponse = response;
            window.localStorage.setItem("install_configReady",JSON.stringify(installResponse.configReady));
            window.localStorage.setItem("install_studyStarted",JSON.stringify(installResponse.studyStarted));
            window.localStorage.setItem("install_configNotReadyErrors",JSON.stringify(installResponse.errors));
            console.log("updateInstallStatus: ", installResponse);
        },
        error => {
            console.log("updateInstallStatus error",error.toString());
        }
    );
}

