import { authHeader } from '../helpers';
import { authHeaderForm } from '../helpers';
//import { isConfigReady } from '../helpers';
import { isStudyStarted } from '../helpers';
import { updateInstallStatus } from '../helpers';

export const userService = {
    login,
    logout,
    getCurrentUser
};

export const participantsService={
    getParticipant,
    getAllParticipants,
    getMyParticipants,
    getParticipantSummary,
    saveParticipant,addParticipant
}
export const providersService={
    getAllProviders,
    saveProvider,
    addProvider
}
export const alarmsService={
    getAllAlarms,
    saveAlarm,
    addAlarm,
    deleteAlarm
}
export const conceptsService={
    getAllConcepts
}
export const questionsService={
    getAllQuestions,
    getQuestionsByForm,
    saveQuestion,
    addQuestion,
    deleteQuestion
}
export const formsService={
    getForms
}
export const answersService={
    getAllAnswers,
    saveAnswer,
    addAnswer
}
export const screensService={
    getAllScreens,
    saveScreen,
    addScreen
}
export const wallpapersService={
    getAllWallpapers,
    saveWallpaper,
    addWallpaper,
    deleteWallpaper
}
export const settingsService={
    getAllSettings,
    saveSetting,
    addSetting
}
export const overviewstatsService={
    getAllStats,
    getMyStats
}
export const proxysService={
    addUserProxy
}
export const lookupService={
    getAllConcepts,
    getAllForms,
    getFormsByType
}
export const installService={
    getInstallStatus,
    recheckInstallStatus,
    saveInstallStatus
}

export const ecountersService={
    getEncounterById
}

export var currentUser = {};

const configProxyUrl = "https://srv001.clintouch-swl.org"; //"http://3.10.239.6:8080"; // process.env.REACT_APP_PROXY_URL; 
const proxyPass = "nFSgb35tjk4h5!b34F9jk4·Djh!1!2rfd";
const configapiUrl = process.env.REACT_APP_API_URL;

const currentURL = window.location.href; // returns the absolute URL of a page
const pathname = window.location.pathname; //returns the current url minus the domain name
const server = currentURL.replace(pathname,'');

// JLIZASO CHANGE: DEMO / PRODUCTION 
// Change in files: .env.production and pom.xml (package.json??) 
// For Dev: localhost
// For Prod: 3.10.239.6
// "http://localhost:8081"// "http://3.10.239.6:8080"; 

//isStudyStarted(false);
//isConfigReady(false);

// JLIZASO CHANGE: DEMO / PRODUCTION 
// Swap comments in configuration-status.js
updateInstallStatus();

//console.log("isConfigReady: ", isConfigReady());
//console.log("isStudyStarted: ", isStudyStarted());
//console.log("JLIZASO process.env.REACT_APP_API_URL: ", process.env.REACT_APP_API_URL);
//console.log("JLIZASO process.env.REACT_APP_PROXY_URL: ", process.env.REACT_APP_PROXY_URL);

function login(username, password) {

    var token = null;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    //console.log("JLIZASO: ", JSON.stringify({ username, password }) )
    return fetch(`${configapiUrl}/clintouch-auth/authenticate`, requestOptions)
        .then(handleResponse)
        .then(response => {
            if (response.token) token = response.token;
            window.localStorage.setItem("usertoken",JSON.stringify( token));
            //console.log("JLIZASO token: ", JSON.stringify( token) )
            return token;
        });
}

function getCurrentUser(id,token){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(token)
    };

    return fetch(`${configapiUrl}/clintouch-server/api/users/login/${id}`, requestOptions).then(handleResponse);
}

function logout() {
    localStorage.clear();
}

function getParticipant(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/participants/${id}`, requestOptions).then(handleResponse);
}

function getAllParticipants() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/participantwithstats`, requestOptions).then(handleResponse);
}

function getMyParticipants(pId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/participantwithstats?userProviderId=${pId}`, requestOptions).then(handleResponse);
}

function getParticipantSummary(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/participants/${id}/summary`, requestOptions).then(handleResponse);
}

function getAllProviders() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/providers`, requestOptions).then(handleResponse);
}

function getAllAlarms() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/alarms`, requestOptions).then(handleResponse);
}

function getAllQuestions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/questions`, requestOptions).then(handleResponse);
}

function getQuestionsByForm(formId) {
    const requestOptions = {
        method: 'GET',

        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/questions?formId=${formId}`, requestOptions).then(handleResponse);
}

function getForms() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/forms`, requestOptions).then(handleResponse);
}

function getAllAnswers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/answers`, requestOptions).then(handleResponse);
}

function getAllScreens() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/screens`, requestOptions).then(handleResponse);
}

function getAllWallpapers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/wallpapers`, requestOptions).then(handleResponse);
}

function getAllSettings() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/settings`, requestOptions).then(handleResponse);
}

function getAllStats() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/overviewstats`, requestOptions).then(handleResponse);
}

function getMyStats(pId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/overviewstats/${pId}`, requestOptions).then(handleResponse);
}

function getAllConcepts(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/concepts`, requestOptions).then(handleResponse);
}

function getAllForms(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/forms`, requestOptions).then(handleResponse);
}

function getFormsByType(type){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/forms?type=${type}`, requestOptions).then(handleResponse);
}

function getEncounterById(eId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/encounters/${eId}`, requestOptions).then(handleResponse);
}

function handleResponseWithInstallStatus(response) { //JLIZASO
    console.log("handleResponseWithInstallStatus: ", response);
    if(!isStudyStarted()){
        updateInstallStatus()
    }

    return handleResponse(response);
}

function handleResponse(response) {
    return response.ok? response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.href = window.BASE_URL;
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    }) : false;
}

function saveParticipant(participant,id){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(participant),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/participants/${id}`, requestOptions).then(handleResponseWithInstallStatus);
}


function addParticipant(participant){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(participant),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/participants`, requestOptions).then(handleResponseWithInstallStatus);
}

function saveProvider(participant,id){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(participant),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/providers/${id}`, requestOptions).then(handleResponse);
}


function addProvider(participant){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(participant),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/providers`, requestOptions).then(handleResponse);
}

function saveAlarm(alarm,id){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(alarm),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/alarms/${id}`, requestOptions).then(handleResponseWithInstallStatus);
}

function addAlarm(alarm){
    console.log(JSON.stringify(alarm));
    console.log(`${configapiUrl}/clintouch-server/api/alarms`);
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(alarm),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/alarms`, requestOptions).then(handleResponseWithInstallStatus);
}

function deleteAlarm(id){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/alarms/delete/${id}`, requestOptions).then(handleResponseWithInstallStatus);
}


function saveQuestion(question,uuid){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(question),
        headers: authHeader()
    };

    console.log("saveQuestion: ",uuid, JSON.stringify(question))
    return fetch(`${configapiUrl}/clintouch-server/api/questions/${uuid}`, requestOptions).then(handleResponseWithInstallStatus);
}

function addQuestion(question){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(question),
        headers: authHeader()
    };

    console.log("AddQuestion: ",JSON.stringify(question))
    return fetch(`${configapiUrl}/clintouch-server/api/questions`, requestOptions).then(handleResponseWithInstallStatus);
}

function deleteQuestion(uuid){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/questions/delete/${uuid}`, requestOptions).then(handleResponseWithInstallStatus);
}


function saveAnswer(answer,id){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(answer),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/answers/${id}`, requestOptions).then(handleResponse);
}

function addAnswer(answer){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(answer),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/answer`, requestOptions).then(handleResponse);
}

function saveScreen(screen,id){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(screen),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/screens/${id}`, requestOptions).then(handleResponse);
}

function addScreen(screen){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(screen),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/screens`, requestOptions).then(handleResponse);
}

function addUserProxy(proxy){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(proxy),
        headers: {'Content-Type': 'application/json; charset=utf8'}
    };

    return fetch(`${configProxyUrl}/clintouch-proxy/api/proxys/newUser?pass=${proxyPass}`, requestOptions).then(handleResponse);
}

function saveSetting(setting,id){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(setting),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/settings/${id}`, requestOptions).then(handleResponse);
}

function addSetting(setting){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(setting),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/settings`, requestOptions).then(handleResponse);
}


function saveWallpaper(wallpaper,id){
    var formData = new FormData()
    formData.append("title", wallpaper.title);
    formData.append("wallpaperId", id);
    formData.append("file", wallpaper.file);

    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: authHeaderForm()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/wallpapers`, requestOptions).then(handleResponse);
}

function addWallpaper(wallpaper){
    var formData = new FormData()
    formData.append("title", wallpaper.title);
    formData.append("file", wallpaper.file);
    
    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: authHeaderForm()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/wallpapers`, requestOptions).then(handleResponse);
}

function deleteWallpaper(id){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/wallpapers/delete/${id}`, requestOptions).then(handleResponse);
}

export const requestResetPass = username => {
    var formData = new FormData();
    formData.append("userName", username);
    formData.append("domain", server);

    const requestOptions = {
        method: 'POST',
        body: formData,
        headers: authHeader()
    }

    return fetch(`${configapiUrl}/clintouch-server/api/passwordreset/sendemaildomain`, requestOptions).then(handleResponse);
}

export const checkResetTokenPass = token => {
    const requestOptions = {
        method: 'POST',
        body: token,
        headers: authHeader()
    }

    return fetch(`${configapiUrl}/clintouch-server/api/passwordreset/checkuuid`, requestOptions).then(handleResponse);
}

export const resetPass = req => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(req),
        headers: {'Content-Type': 'application/json; charset=utf8'}
    }

    return fetch(`${configapiUrl}/clintouch-server/api/passwordreset/resetpassword`, requestOptions).then(handleResponse);
}

function getInstallStatus() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/installs`, requestOptions).then(handleResponse);
}

function recheckInstallStatus() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/installs`, requestOptions).then(handleResponse); // JLIZASO DEMO/PRODUCTION: Add "/force" to the address
}

function saveInstallStatus(installData) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(installData),
        headers: authHeader()
    };

    return fetch(`${configapiUrl}/clintouch-server/api/installs`, requestOptions).then(handleResponse);
}