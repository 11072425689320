/*eslint-disable*/
import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { isStudyStarted } from '../../helpers';
import { settingsActions } from '../../actions';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.props.getAll();
    this.state = {
      data: []
    };
  }

  render() {
    const { settings } = this.props;

    if (settings && settings.length > 0) {
      var dataItems = settings.map((el) => {

        var actions = <div className="actions-right">
              <Button
                onClick={() => {
                  this.props.history.push({
                    pathname: `/admin/settings/${el.id}/edit`,
                    state: { theSetting: el }
                  });
                }}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fa fa-edit" />
              </Button>
            </div>;
        
        if(isStudyStarted()){
          actions = <div className="actions-right"></div>;
        }

        return {
          id: el.id,
          type: el.type,
          title: el.title,
          value: el.value + " " + el.uom,
          actions: actions
        };
      });
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Settings</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={dataItems}
                    filterable
                    columns={[
                      {
                        Header: "Setting",
                        accessor: "title"
                      },
                      {
                        Header: "Value",
                        accessor: "value"
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { settings } = state.settings;
  return { settings };
}

const actionCreators = {
  getAll: settingsActions.getAll
};

const connectedSettings = connect(mapState, actionCreators)(Settings);
export { connectedSettings as Settings }; 