
import { genericConstants } from '../constants';

const initialState = {
    overviewstatsLoading: false, 
    summary: {},
    error: ""
};

 
export function overviewstats(state = initialState, action) {
    switch (action.type) {
        case genericConstants.OVERVIEWSTATS_REQUEST:
            return {
                overviewstatsLoading: true
            };

        case genericConstants.OVERVIEWSTATS_SUCCESS:
            return {
                overviewstatsLoading: false,
                overviewstats: action.items,
                summary:{},
                error: ""
            };

        case genericConstants.OVERVIEWSTATS_FAILURE:
            return {
                overviewstatsLoading: false,
                overviewstats: [],
                summary:{},
                error: action.error
            };

            default:
            return state
    }
}