export const genericConstants = {
    LOOKUPS_GET_REQUEST: 'LOOKUPS_GET_REQUEST' ,
    LOOKUPS_GET_SUCCESS_CONCEPTS: 'LOOKUPS_GET_SUCCESS_CONCEPTS' ,
    LOOKUPS_GET_SUCCESS_FORMS: 'LOOKUPS_GET_SUCCESS_FORMS' ,
    LOOKUPS_GET_SUCCESS_FORMSBT: 'LOOKUPS_GET_SUCCESS_FORMSBT' ,
    LOOKUPS_GET_FAILURE: 'LOOKUPS_GET_FAILURE' ,

    PARTICIPANTS_REQUEST: 'PARTICIPANTS_GETALL_REQUEST' ,
    PARTICIPANTS_SUCCESS: 'PARTICIPANTS_GETALL_SUCCESS' ,
    PARTICIPANTS_FAILURE: 'PARTICIPANTS_GETALL_FAILURE' ,

    PARTICIPANT_REQUEST: 'PARTICIPANT_GETALL_REQUEST' ,
    PARTICIPANT_SUCCESS: 'PARTICIPANT_GETALL_SUCCESS' ,
    PARTICIPANT_FAILURE: 'PARTICIPANT_GETALL_FAILURE' ,

    PARTICIPANTS_SUMMARY_REQUEST: 'PARTICIPANTS_GETSUMMARY_REQUEST' ,
    PARTICIPANTS_SUMMARY_SUCCESS: 'PARTICIPANTS_GETSUMMARY_SUCCESS' ,
    PARTICIPANTS_SUMMARY_FAILURE: 'PARTICIPANTS_GETSUMMARY_FAILURE' ,

    PROVIDERS_REQUEST: 'PROVIDERS_GETALL_REQUEST' ,
    PROVIDERS_SUCCESS: 'PROVIDERS_GETALL_SUCCESS' ,
    PROVIDERS_FAILURE: 'PROVIDERS_GETALL_FAILURE' ,

    ALARMS_REQUEST: 'ALARMS_GETALL_REQUEST' ,
    ALARMS_SUCCESS: 'ALARMS_GETALL_SUCCESS' ,
    ALARMS_FAILURE: 'ALARMS_GETALL_FAILURE' ,

    CONCEPTS_REQUEST: 'CONCEPTS_GETALL_REQUEST' ,
    CONCEPTS_SUCCESS: 'CONCEPTS_GETALL_SUCCESS' ,
    CONCEPTS_FAILURE: 'CONCEPTS_GETALL_FAILURE' ,

    QUESTIONS_REQUEST: 'QUESTIONS_GETALL_REQUEST' ,
    QUESTIONS_SUCCESS: 'QUESTIONS_GETALL_SUCCESS' ,
    QUESTIONS_FAILURE: 'QUESTIONS_GETALL_FAILURE' ,

    FORMS_REQUEST: 'FORMS_GETALL_REQUEST' ,
    FORMS_SUCCESS: 'FORMS_GETALL_SUCCESS' ,
    FORMS_FAILURE: 'FORMS_GETALL_FAILURE' ,

    ANSWERS_REQUEST: 'QUESTIONS_GETALL_REQUEST' ,
    ANSWERS_SUCCESS: 'QUESTIONS_GETALL_SUCCESS' ,
    ANSWERS_FAILURE: 'QUESTIONS_GETALL_FAILURE' ,

    SCREENS_REQUEST: 'SCREENS_GETALL_REQUEST' ,
    SCREENS_SUCCESS: 'SCREENS_GETALL_SUCCESS' ,
    SCREENS_FAILURE: 'SCREENS_GETALL_FAILURE' ,

    WALLPAPERS_REQUEST: 'WALLPAPERS_GETALL_REQUEST' ,
    WALLPAPERS_SUCCESS: 'WALLPAPERS_GETALL_SUCCESS' ,
    WALLPAPERS_FAILURE: 'WALLPAPERS_GETALL_FAILURE' ,

    SETTINGS_REQUEST: 'SETTINGS_GETALL_REQUEST' ,
    SETTINGS_SUCCESS: 'SETTINGS_GETALL_SUCCESS' ,
    SETTINGS_FAILURE: 'SETTINGS_GETALL_FAILURE' ,

    OVERVIEWSTATS_REQUEST: 'OVERVIEWSTATS_GETALL_REQUEST',
    OVERVIEWSTATS_SUCCESS: 'OVERVIEWSTATS_GETALL_SUCCESS',
    OVERVIEWSTATS_FAILURE: 'OVERVIEWSTATS_GETALL_FAILURE',

    INSTALL_STATUS_REQUEST: 'INSTALL_STATUS_REQUEST' ,
    INSTALL_STATUS_SUCCESS: 'INSTALL_STATUS_SUCCESS' ,
    INSTALL_STATUS_FAILURE: 'INSTALL_STATUS_FAILURE' ,

    //LOGIN
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
};