/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Form } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

class TermsnConditions extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        const styles = {
            padding: {
                paddingLeft: "5%",
                paddingRight: "5%"
            }
        };

        return (

            <>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Affigo CIC Terms & Conditions</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Row style={styles.padding}>
                                                    <Col xs={12} md={12}>
                                                        <p>This is the Terms & Conditions notice of the Affigo CIC (“Affigo CIC”, “we”, “us”, “our”) Clinician Dashboard 
                                                            <br/>We are company number 09928775 registered in the United Kingdom.
                                                            <br/>Our registered office is at Affigo CIC, Suite 308, 275 Deansgate, Manchester, M3 4EL, England
                                                            <br/>
                                                            <br/>Effective date: 
                                                            <br/>Last reviewed: 14/01/2021</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">1. Introduction</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>1.1 </b> The ClinTouch Clinician Dashboard was built to work in conjunction with the ClinTouch App as a digital tool for symptom collection and self-management for people with serious mental illness.  It is owned and developed by the not-for-profit company, Affigo CIC. By using the Clinician Dashboard, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the Clinician Dashboard.</p>
                                                        <p><b>1.2 Contacting us. </b> To find out more about who we are and our role, visit the <a href="https://affigo.io/" target="_blank">Affigo website</a>. If you think the ClinTouch App is faulty, or you wish to contact us, please email us at <a href="support@affigo.io">support@affigo.io</a></p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">2. When these terms apply</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>2.1 </b>These terms and conditions ("terms of use") apply to your use of the ClinTouch Clinician Dashboard. Whenever you use any version of the ClinTouch Clinican Dashboard, you agree to our terms of use.</p>
                                                        <p><b>2.2 </b>Read these terms of use before using our Clinician Dashboard.</p>
                                                        <p><b>2.3 </b>By agreeing to our terms of use, you are also agreeing to comply with:</p>
                                                        <ul>
                                                            <li>our <b>Clinician Dashboard Privacy Policy</b>, which may be updated from time to time, and which sets out the terms on which we process any personal data we collect from you</li>
                                                            <li>any other separate terms of use, privacy policies, cookies policies and other terms and conditions we may notify you of</li>
                                                        </ul>
                                                        <p><b>2.4 </b>We may, at any time and in our sole discretion, amend these terms of use for any reason. For example, to comply with law or reflect changes to or new features on the ClinTouch Clinician Dashboard. The latest version of our terms of use will be accessible through our <a href="https://affigo.io/" target="_blank">website</a>, therefore you are advised to review this page periodically for any changes. These changes are effective immediately after they are posted on our website.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">3. How to register to use the ClinTouch Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>3.1 </b> This Clinician Dashboard is provided by Affigo CIC and is intended for use as part of a research project or implementation study or clinical plan. Please note that the ClinTouch Clinician Dashboard and associated App is never intended as a substitute for clinical care.</p>
                                                        <p><b>3.2 </b> The Clinician Dashboard and App should only be used in these contexts for which it has been designed and under the supervision of the end user’s clinical team. It is not intended for public use as is.</p>
                                                        <p><b>3.3 </b> Services and arrangements may differ elsewhere outside the UK. If you access or attempt to access the ClinTouch Clinician Dashboard from any location outside the UK, you are responsible for complying with any local laws that apply to you in the country from which you are using the ClinTouch Clinician Dashboard.</p>
                                                        <p><b>3.4 </b> Use of the ClinTouch Clinician Dashboard is not authorised in any country outside the UK that does not give effect to all provisions of these terms of use, including without limitation the choice of law and jurisdiction clauses.</p>
                                                        <p><b>3.5 </b> We have many measures in place to keep your data safe. But it is important that you also play your part – visit the government's Cyber Aware website for advice on how to do this.</p>
                                                        <p><b>3.6 </b> Affigo accepts no liability for any loss, direct or indirect, you experience as a result of using the ClinTouch Clinician Dashboard.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">4. Accessing the ClinTouch Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>4.1 </b> You are responsible for making all arrangements necessary for you to access the ClinTouch Clinician Dashboard, including but not limited to an internet connection and an appropriate device ("Device") for access. The connection can be Wi-Fi, or provided by your mobile network provider, but Affigo cannot take responsibility for the Clinician Dashboard not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.</p>
                                                        <p><b>4.2 </b> If you are using the ClinTouch Clinician Dashboard outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the Clinician Dashboard, or other third party charges. In using the Clinician Dashboard, you are accepting responsibility for any such charges, including data roaming charges if you use the Clinician Dashboard outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you are using the Clinician Dashboard, please be aware that we assume that you have received permission from the bill payer.</p>
                                                        <p><b>4.3 </b> We are committed to ensuring that the Clinician Dashboard is as useful and efficient as possible. For that reason, we reserve the right to make changes to the Clinician Dashboard or to charge for its services, at any time and for any reason. We will never charge you for the Clinician Dashboard without making it very clear to you exactly what you are paying for without your prior approval.</p>
                                                        <p><b>4.4 </b> We may suspend, withdraw, discontinue or change all or any part of the ClinTouch Clinician Dashboard without notice. We will not be liable to you if for any reason the ClinTouch Clinician Dashboard are unavailable at any time or for any period. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the Clinician Dashboard, and (if needed) delete it from your device.</p>
                                                        <p><b>4.5 </b> We are giving you and your clinical team the right to access and use the ClinTouch Clinician Dashboard. You may not transfer the ClinTouch Dashboard to anyone else. If you sell, loan or otherwise dispose of any device on which the ClinTouch Clinician Dashboard is accessed, you must remove any login details for the ClinTouch Clinician Dashboard from the device beforehand.</p>
                                                        <p><b>4.6 </b> The ClinTouch Clinician Dashboard is only intended to be used by you and your clinical team. </p>
                                                        <p><b>4.7 </b> When using the ClinTouch Clinician Dashboard you should ensure you are using a secure internet connection, please refer to the government's Cyber Aware website for more guidance.</p>
                                                        <p><b>4.8 </b> In order to operate correctly, the ClinTouch Clinician Dashboard requires you to use a device, operating system and browser that comply with certain minimum requirements. </p>
                                                        <p><b>4.9 </b> You are not allowed to copy, or modify the ClinTouch Clinician Dashboard, any part of the ClinTouch App, or our trademarks in any way. You are not allowed to attempt to extract the source code of the ClinTouch Clinician Dashboard or App, and you should not try to translate the Clinician Dashboard or App into other languages, or make derivative versions.</p>
                                                        <p><b>4.10 </b> The ClinTouch Clinician Dashboard and App, their derivatives, and all the trademarks, copyright, database rights and other intellectual property rights related to it, belong to Affigo CIC.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">5. Updates to the ClinTouch Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                    <p><b>5.1 </b> From time to time we may update the ClinTouch Dashboard to improve performance, enhance functionality, reflect changes to the operating system, or address security issues. </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">6. Ending your use of ClinTouch Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>6.1 </b> If your ClinTouch account is cancelled or terminated, this may mean you will not be able to login to the ClinTouch Clinician Dashboard. </p>
                                                        <p><b>6.2 </b> If you breach any of these terms of use, we may prevent you from accessing the ClinTouch Clinician Dashboard and cancel your ClinTouch account. If we terminate (or suspend) your access to the ClinTouch Clinician Dashboard, we will notify you. If what you have done can be put right, we will tell you and give you a reasonable opportunity to do so. </p>
                                                        <p><b>6.3 </b> If you cancel your ClinTouch Clinician Dashboard account or we end your rights to use the ClinTouch Clinician Dashboard:</p>
                                                        <ul>
                                                            <li>you must stop all activities authorised by these terms of use, including your use of the ClinTouch Clinician Dashboard</li>
                                                            <li>where we end your rights to use the ClinTouch Clinician Dashboard, you must not seek to create a new account without our prior written consent</li>
                                                            <li>any personal data we hold about you will be dealt with in accordance with our data retention policy, which is set out in our <a href="https://affigo.io/" target="_blank">privacy policy</a></li>
                                                            <li>all rights granted to you under these terms of use shall automatically cease without further notice (although your statutory rights - for example in respect of data protection as described in the privacy policy - are not affected)</li>
                                                            <li>(where applicable) you must immediately delete or remove all copies of the ClinTouch Clinician Dashboard from all devices or other IT equipment in your possession</li>
                                                            <li>we will cease providing you with access to the ClinTouch Clinician Dashboard</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">7. Your right to use the ClinTouch Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>7.1 </b> We either own or have the right to use for the purposes of providing the ClinTouch Clinician Dashboard all intellectual property rights, including rights in copyright, patents, database rights, trademarks and other intellectual property rights (“Affigo IPR”) in:</p>
                                                        <ul>
                                                            <li>the ClinTouch Clinician Dashboard and App</li>
                                                            <li>any materials or content of whatever nature available through the ClinTouch Clinician Dashboard and App including any information, data, text, images, videos, interactive services, links to products and apps, and other works in any format ("<b>Online Content</b>")</li>
                                                        </ul>
                                                        <p>Save as expressly permitted in these terms of use, you will need to obtain permission in writing from us or any other owner of any Affigo IPR (where we are the licensee) before you may use these items in any way. We do not sell the ClinTouch Clinician Dashboard, App, Connected Services or any Online Content to you, and we or our licensors remain the owners of such materials at all times.</p>
                                                        <p><b>7.2 Permission to use Affigo IPR. </b> As long as you comply with these terms of use, we grant you a personal, worldwide, perpetual, non-exclusive, non-transferable, revocable, limited licence to use the ClinTouch Clinician Dashboard. We reserve all other licence rights not expressly permitted under these terms of use.</p>
                                                        <p><b>7.3 </b> Unless permitted by law or under these terms of use, you will:</p>
                                                        <ul>
                                                            <li>not copy the ClinTouch Clinician Dashboard, App or any Affigo IPR except where such copying is incidental to normal use</li>
                                                            <li>not rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the ClinTouch Clinician Dashboard, App or any Affigo IPR</li>
                                                            <li>not modify the ClinTouch Clinician Dashboard, App or any Affigo IPR, or permit them to be combined with, or become incorporated in, any other programmes or services</li>
                                                            <li>not disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the ClinTouch Clinician Dashboard, App or any other Affigo IPR</li>
                                                            <li>comply with all technology control or export laws that apply to the technology used by the ClinTouch Clinician Dashboard, App or other any Affigo IPR.</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">8. Prohibited uses</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>8.1 </b> You may not:</p>
                                                        <ul>
                                                            <li>infringe Affigo IPR in relation to your use of the ClinTouch Clinician Dashboard to the extent that such use is not expressly permitted by these terms of use</li>
                                                            <li>transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the ClinTouch Clinician Dashboard</li>
                                                            <li>collect any data from our systems or any other systems or attempt to decipher any transmissions to or from the servers running the ClinTouch Clinician Dashboard and App</li>
                                                            <li>use the ClinTouch Clinician Dashboard on any device or operating system that has been modified outside the operating system vendor supported or warranted configurations</li>
                                                            <li>use the ClinTouch Clinician Dashboard in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users</li>
                                                            <li>use the ClinTouch Clinician Dashboard in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms of use, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses or harmful data, into the ClinTouch Clinician Dashboard</li>
                                                            <li>use the ClinTouch Clinician Dashboard in connection with any kind of denial-of-service attack whether in relation to the ClinTouch Clinician Dashboard or otherwise. By breaching this provision, you may be committing a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the ClinTouch Clinician Dashboard will cease immediately</li>
                                                            <li>use the ClinTouch Clinician Dashboard in any way that constitutes improper use or otherwise in a manner not reasonably contemplated by these terms of use</li>
                                                            <li>use the ClinTouch Clinician Dashoard to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, spyware or any other harmful programmes or similar computer code designed to adversely affect the operation of any computer software or hardware</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">9. Our liability to you</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>Please read this clause carefully, as it sets out the limits of our liability to you in relation to your use of the ClinTouch Clinician Dashboard</b></p>
                                                        <p><b>9.1 </b>The ClinTouch Clinician Dashboard may contain technical inaccuracies or typographical errors. We reserve the right at any time and without notice to make changes and improvements to the ClinTouch Clinician Dashboard.</p>
                                                        <p><b>9.2 </b> Although we make reasonable efforts to update them, the ClinTouch Clinician Dashboard is provided "as is" and, to the extent permitted by law, we make no representations, warranties or guarantees, whether express or implied (including but not limited to the implied warranties of satisfactory quality and fitness for a particular purpose), that the ClinTouch Clinician Dashboard is (a) accurate, complete or up-to-date; (b) meet your particular requirements or needs; or (c) access to, or use of, the same will be uninterrupted or completely secure. This is because we are completely reliant on third parties to provide accurate and correct data for the ClinTouch Clinician Dashboard.</p>
                                                        <p><b>9.3 </b> If the need arises, we reserve the right to amend, delete, suspend or withdraw all or any part of the ClinTouch Clinician Dashboard without notice. We will not be liable if, for any reason, the ClinTouch Clinician Dashboard or any parts are unavailable at any time.</p>
                                                        <p><b>9.4 </b> We cannot guarantee that the ClinTouch Clinician Dashboard will be uninterrupted or error free, that defects will be corrected, or that the ClinTouch Clinician Dashboard or the servers that make them available will be free of viruses or represent the full functionality, accuracy or reliability of the materials. You should use your own virus protection software (and ensure that it is regularly updated) when accessing and using the ClinTouch Clinician Dashboard, and you acknowledge that the introduction of threats or viruses may be as a result of circumstances that are not within our control.</p>
                                                        <p><b>9.5 </b> Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, any loss or damage to a device or digital content belonging to you, if you can show that i) this was caused by the ClinTouch Clinician Dashboard and ii) we failed to use reasonable skill and care to prevent this, or any other liability that cannot be excluded or limited under English law.</p>
                                                        <p><b>9.6 </b> Subject to clause 9.5 of these terms of use, we will not be liable or responsible to you for:</p>
                                                        <ul>
                                                            <li>any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your device, computer equipment, computer programmes, data or other proprietary material due to your use of the ClinTouch Clinician Dashboard</li>
                                                            <li>any loss or damage that was not caused by our breach of these terms of use</li>
                                                            <li>any business loss (including but not limited to loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure)</li>
                                                            <li>any indirect or consequential losses that were not foreseeable to both you and us when you commenced using the ClinTouch Clinician Dashboard (loss or damage is "foreseeable" if it was an obvious consequence of our breach of these terms of use or if they were contemplated by you and us at the time we entered into the contract created by your use of the ClinTouch Clinician Dashboard)</li>
                                                            <li>any loss or damage arising from an inability to access and/or use the ClinTouch Clinician Dashboard in whole or in part</li>
                                                            <li>use or reliance on any content available through the ClinTouch Clinician Dashboard regardless of the origins of such content unless due to our breach or negligence</li>
                                                            <li>any other loss or damage whether arising under tort (including negligence), breach of contract, breach of statutory duty or otherwise</li>
                                                        </ul>
                                                        <p><b>9.7 </b> This clause 9 does not affect any legal rights you may have as a consumer in relation to defective services or software. Advice about your legal rights is available from your local Citizen's Advice or Trading Standards Office.</p>
                                                        <p><b>9.8 </b> You acknowledge that we have made the ClinTouch Clinician Dashboard available to you in reliance upon these terms of use (including the exclusions and limits of liability in this clause 10).</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">10. General</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>10.1 </b>We may transfer our rights and obligations under these terms of use to another organisation. We will always tell you in writing if this happens, and we will ensure that the transfer will not affect your rights under these terms of use. You may only transfer your rights or your obligations under these terms of use to another person if we agree in writing.</p>
                                                        <p><b>10.2 </b>Subject to any specific terms detailed on the ClinTouch Clinician Dashboard in relation to particular features or materials, these terms of use sets out the entire agreement between you and us in respect of your use of the ClinTouch Clinician Dashboard. </p>
                                                        <p><b>10.3 </b>These terms of use do not give rise to any rights for any third party to enforce any term of these terms of use.</p>
                                                        <p><b>10.4 </b>We will not be in breach of any of our obligations under these terms of use (or otherwise liable for any failure or delay in performance) if we are prevented, hindered or delayed in or from performing any of our obligations by any event beyond our reasonable control. The time for performance of our obligations shall be extended accordingly.</p>
                                                        <p><b>10.5 </b>Each of the clauses of these terms of use operates separately. If any part of these terms of use is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and exclusions and limits of liability, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these terms of use shall continue in effect (and if any of the sub-clauses in clause 9.6 of these terms of use are held to be invalid and are struck out, then this shall not impact on any of the remaining sub-clauses).</p>
                                                        <p><b>10.6 </b>Even if we delay in enforcing these terms of use, we can still enforce them later. If we do not insist immediately that you do anything you are required to do under these terms of use, or if we delay in taking steps against you in respect of your breaking these terms of use, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
                                                        <p><b>10.7 </b>The laws of England shall apply exclusively to these terms of use and to all matters relating to use of the ClinTouch Clinician Dashboard. Any cause of action arising under these terms of use or the use of the ClinTouch Clinician Dashboard shall be subject to the exclusive jurisdiction of the courts of England.</p>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


function mapState(state) {
}

const connectedTermsnConditions = connect(mapState)(TermsnConditions);
export { connectedTermsnConditions as TermsnConditions }; 