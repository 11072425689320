/* eslint-disable */
import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Form, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { isStudyStarted } from '../../helpers';
import { questionsActions, conceptsActions } from '../../actions';
import { questionsService } from '../../services';

class QuestionsEdit extends Component {
    constructor(props) {
        super(props);
        var pid = props.match.params.pid;
        var pform_id = props.match.params.pform;
        var pform_type = props.match.params.ptype;
        var readOnly = isStudyStarted();
        var pmin_id = 0;
        var pmax_id = 0;

        console.log("Questions Edit: ", pid, pform_id, readOnly);

        switch (pform_type) {
            case "CORE":
                pmin_id = 0;
                pmax_id = 200;
                break;
            case "DELUSIONS":
                pmin_id = 300;
                pmax_id = 400;
                break;
            case "SELF_HARM":
                pmin_id = 200;
                pmax_id = 300;
                break;
            default:
                break;
        }

        this.state = {
            min_id: pmin_id,
            max_id: pmax_id,
            form_id: pform_id,
            form_type: pform_type,
            readOnly: readOnly,
            alert: null,
            editAnswerPopup: null,
            show: false,
            id: pid,
            data: [],
            isAdd: false,
            validation: {
                id: 'has-danger',
                text: 'has-danger',
                concept: 'has-danger',
                changeText: 'has-danger',
                changeValue: 'has-danger',
                rangeAnswers: '',
                rangeOrOptionAnswers: '',
                numberOfAnswers: '',
                noAnswers: 'has-danger',
                branching: ''               
            },
            uuid: '',
            id: '',
            text: '',
            reverseResult: null,
            leftLabel: '',
            rightLabel: '',
            answerValues: '',
            hasAnswers: '',
            changeText: '',
            changeValue: '',
            changeUuid: '',
            changeType: '',
            concept: null,

            mobileGraphingCategory: '',
            branchType: 'NO_BRANCH',
            mobileGraphingCategory: 'NONE',
            numQuestionsToSkip: '',
            branchValue: '',
            otherBranchIds: null,

            showDialog: 'hiddenDialog',
            indexAnswer: 0,
            dataItems: [],
            answers: []
        };

        console.log("constructor lconcepts: ", props.lconcepts);
        if (props.lconcepts === undefined || props.lconcepts === null || props.lconcepts.length === 0) {
            props.getConcepts();
        }


        if (props.location.state && props.location.state.theQuestion) {
            var p = props.location.state.theQuestion;
            if (p && p !== null) {
                console.log("Question:", p);
                this.state.uuid = p.uuid;
                this.state.id = p.id;
                this.state.text = p.text;

                console.log("Asignation p.reverseResult: ", p.reverseResult);
                if(p.reverseResult == "true" || p.reverseResult === true){
                    this.state.reverseResult = true;
                } else {
                    this.state.reverseResult = false;
                }
                console.log("Asignation this.state.reverseResult: ", this.state.reverseResult);

                this.state.leftLabel = p.leftLabel;
                this.state.rightLabel = p.rightLabel;
                this.state.answerValues = p.answerValues;
                this.state.hasAnswers = p.answers.length;
                this.state.answers = p.answers;

                if (p.concept && p.concept !== null) {
                    this.state.concept = {
                        value: p.concept.id,
                        label: p.concept.name
                    };
                }

                this.state.mobileGraphingCategory = p.mobileGraphingCategory;
                this.state.numQuestionsToSkip = p.numQuestionsToSkip;
                this.state.branchValue = p.branchValue;
                this.state.otherBranchIds = p.otherBranchIds;

                this.state.branchType = p.branchType;

                this.state.validation = {
                    id: '',
                    text: '',
                    concept: '',
                    answers: '',
                    leftLabel: '',
                    rightLabel: '',
                    changeText: '',
                    changeValue: '',
                    rangeAnswers: '',
                    rangeOrOptionAnswers: '',
                    numberOfAnswers: '',
                    noAnswers: '',
                    branching: ''
                }
            } else {
                this.props.history.goBack();
            }
        } else {
            this.state.isAdd = true;
        }
        //console.log("this.state.branchType: ", this.state.branchType);
    }

    handleConceptChange(value) {
        this.setState({ concept: value })
        this.setState({
            validation: {
                ...this.state.validation,
                concept: "has-success"
            }
        }); 
    }

    handleMobileGraphingCategory(value) {
        this.setState({ mobileGraphingCategory: value.value });
    }

    handleBranchTypeChange(value) {
        console.log("value: ", value);
        this.setState({ branchType: value.value });

        if (value.value=='NO_BRANCH') {
            this.setState({ 'branchValue': '' });
            this.setState({ 'numQuestionsToSkip': '' });
            this.setState({ 'otherBranchIds': '' });

            this.state.validation["branching"] = "has-success";
        } else {
            if(this.state.branchValue>=0 && this.state.numQuestionsToSkip>=1){
                this.state.validation["branching"] = "has-success";
            } else {
                this.state.validation["branching"] = "has-danger";
            }
        }
    }

    handleChangeChecked = (e) => {
        console.log("handleChangeChecked e: ", e);
        console.log("handleChangeChecked e.target: ", e.target);
        console.log("handleChangeChecked e.target.value: ", e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        
        //console.log("handleChange: ", name, value);

        if(name=='otherBranchIds' || name=='branchValue' || name=='numQuestionsToSkip'){
            if(this.state.branchType != 'NO_BRANCH'){

                if(name=='otherBranchIds'){
                    try{
                        if(value.length > 1 && value[value.length - 1] == "," && value.slice(-2) != ",,"){
                            this.setState({ [name]: value });
                            this.state.validation["otherBranchIds"] = "has-success";
                        } else {
                            var array = JSON.parse("[" + value + "]")
                            this.setState({ [name]: array });

                            var res = array.every(function(element) {return typeof element === 'number';});
                            if(res){
                                this.state.validation["otherBranchIds"] = "has-success";
                            } else {
                                this.state.validation["otherBranchIds"] = "has-danger";
                            }
                        }
                    } catch(e) {
                        this.state.validation["otherBranchIds"] = "has-danger";
                    }
                } else {
                    this.setState({ [name]: value });
                }

                if(name=='branchValue'){
                    if(value>=0 && this.state.numQuestionsToSkip>=1){
                        this.setState({ [name]: value });
                        this.state.validation["branching"] = "has-success";
                    } else {
                        this.state.validation["branching"] = "has-danger";
                    }
                }
                
                if(name=='numQuestionsToSkip'){
                    if(this.state.branchValue>=0 && value>=1){
                        this.setState({ [name]: value });
                        this.state.validation["branching"] = "has-success";
                    } else {
                        this.state.validation["branching"] = "has-danger";
                    }
                }
            }
        } else {
            this.setState({ [name]: value }, ()=>{this.validations()} );
        }

        //console.log("HandleChange, Target, name, value: ",e, e.target, name, value);
        
        switch (name) {
            case 'id':
                if (parseInt(value) > this.state.min_id && parseInt(value) < this.state.max_id) {
                    this.state.validation["id"] = "has-success";
                } else {
                    this.state.validation["id"] = "has-danger";
                }
                break;
            case 'text':
                if (value.length >= 10) {
                    this.state.validation["text"] = "has-success";
                } else {
                    this.state.validation["text"] = "has-danger";
                }
                break;
            case 'concept':
                if (value != null) {
                    this.state.validation["id"] = "has-success";
                } else {
                    this.state.validation["id"] = "has-danger";
                }
                break;
            case 'answerValues':
                if (value.trim() == "" || (parseInt(value) >= 2 && parseInt(value) <= 10)) {
                    this.state.validation["answerValues"] = "has-success";
                } else {
                    this.state.validation["answerValues"] = "has-danger";
                }
                break;
            case 'leftLabel':
                if (value.length < 13) {
                    this.state.validation["leftLabel"] = "has-success";
                } else {
                    this.state.validation["leftLabel"] = "has-danger";
                }
                break;
            case 'rightLabel':
                if (value.length < 13) {
                    this.state.validation["rightLabel"] = "has-success";
                } else {
                    this.state.validation["rightLabel"] = "has-danger";
                }
                break;
            case 'changeText':
                if (value.length > 0 && value.length < 61) {
                    this.state.validation["changeText"] = "has-success";
                } else {
                    this.state.validation["changeText"] = "has-danger";
                }
                break;
            case 'changeValue':
                if (parseInt(value) > -1 && parseInt(value) < 101) {
                    this.state.validation["changeValue"] = "has-success";
                } else {
                    this.state.validation["changeValue"] = "has-danger";
                }
                break;
            default:
                break;
        }
    }

    validations() {
        //console.log("answerValues/answers: ", this.state.answerValues.length, this.state.answers.length);
        //console.log("leftLabel/rightLabel: ", this.state.leftLabel.length, this.state.rightLabel.length);

        if(this.state.answers.length==1){
            this.state.validation["numberOfAnswers"] = "has-danger";
        } else {
            this.state.validation["numberOfAnswers"] = "has-success";
        }

        if(this.state.answerValues>0 && this.state.answers.length>0){
            this.state.validation["rangeOrOptionAnswers"] = "has-danger";
        } else {
            this.state.validation["rangeOrOptionAnswers"] = "has-success";
        }
        
        if(this.state.answerValues>0 ){
            if(this.state.leftLabel!=null && this.state.leftLabel.length>0 && this.state.rightLabel!=null && this.state.rightLabel.length>0 ){
                this.state.validation["rangeAnswers"] = "has-success";
            } else {
                this.state.validation["rangeAnswers"] = "has-danger";
            }
        } else {
            this.state.validation["rangeAnswers"] = "has-success";
            /*
            if(this.state.leftLabel==null || this.state.leftLabel.length>0 || this.state.rightLabel==null || this.state.rightLabel.length>0 ){
                this.state.validation["rangeAnswers"] = "has-danger";
            } else {
                this.state.validation["rangeAnswers"] = "has-success";
            }*/
        }

        if(this.state.answerValues>0 || this.state.answers.length>1 ){
            this.state.validation["noAnswers"] = "has-success";
        } else {
            this.state.validation["noAnswers"] = "has-danger";
        }

        this.setState({ validation: this.state.validation });
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    hideAlert1() {
        this.setState({ alert: null });
        this.props.history.goBack();
    }

    saveQuestion() {
        if (this.state.validation.id === "has-danger" ||
            this.state.validation.text === "has-danger" ||
            this.state.validation.concept === "has-danger" ||
            this.state.validation.leftLabel === "has-danger" ||
            this.state.validation.rightLabel === "has-danger" ||
            this.state.validation.answerValues === "has-danger" ||
            this.state.validation.rangeOrOptionAnswers === "has-danger" ||
            this.state.validation.rangeAnswers === "has-danger" ||
            this.state.validation.noAnswers === "has-danger" ||
            this.state.validation.branching === "has-danger" ||
            this.state.validation.answers === "has-danger" ) {
            this.setState({
                alert: (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "100px" }}
                        title="Something when wrong!"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                    >
                        Please review the errors in red and try again.
                  </SweetAlert>
                )
            });
        } else {
            var theQuestion = {};

            function uuidv4() {
                return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            }

            if (this.state.isAdd !== true) {
                theQuestion = this.props.location.state.theQuestion;
                console.log("theQuestion ORIGINAL: ", theQuestion);
            } else {
                theQuestion.uuid = uuidv4();

                theQuestion.type = this.state.form_type;
                theQuestion.form = { "id": this.state.form_id }
            }

            //theQuestion.formId = this.state.form_id;
            theQuestion.id = this.state.id;
            theQuestion.text = this.state.text;
            theQuestion.mobileGraphingCategory = this.state.mobileGraphingCategory;
            theQuestion.concept = this.state.concept;
            theQuestion.leftLabel = this.state.leftLabel;
            theQuestion.rightLabel = this.state.rightLabel;
            theQuestion.answerValues = this.state.answerValues;
            console.log("SAVE this.state.reverseResult: ", this.state.reverseResult);
            theQuestion.reverseResult = this.state.reverseResult === true;
            theQuestion.answers = this.state.answers;
            
            theQuestion.concept = { "id": this.state.concept.value };

            theQuestion.branchType = this.state.branchType;
            theQuestion.branchValue = parseInt(this.state.branchValue);
            theQuestion.numQuestionsToSkip = parseInt(this.state.numQuestionsToSkip);
            
            var otherBranchIds = this.state.otherBranchIds;
            if(otherBranchIds != null && otherBranchIds.length > 0){
                if(otherBranchIds[otherBranchIds.length - 1] == ","){
                    theQuestion.otherBranchIds = otherBranchIds.substring(0, otherBranchIds.length - 1);
                } else {
                    theQuestion.otherBranchIds = this.state.otherBranchIds;
                }
            } else {
                theQuestion.otherBranchIds = null;
            }

            console.log("theQuestion SAVE: ", theQuestion);

            if (this.state.isAdd !== true) {
                console.log("SAVE: ", theQuestion);
                questionsService.saveQuestion(theQuestion, theQuestion.uuid).then(r => {
                    this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "100px" }}
                                title="Question Saved"
                                onConfirm={() => this.hideAlert1()}
                                onCancel={() => this.hideAlert1()}
                                confirmBtnBsStyle="info"
                            >
                            </SweetAlert>
                        )
                    });

                }
                );
            } else {
                console.log("ADD: ", theQuestion);
                questionsService.addQuestion(theQuestion).then(r => {
                    this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "100px" }}
                                title="Question Saved"
                                onConfirm={() => this.hideAlert1()}
                                onCancel={() => this.hideAlert1()}
                                confirmBtnBsStyle="info"
                            >
                            </SweetAlert>
                        )
                    });
                });
            }
        }
    }



    render() {
        var answers = [];
        var i=0;
        var index=0;

        /*
        var branchTypes = [
            { value: "NO_BRANCH", label: "No branch" },
            { value: "LESS_THAN", label: "Less than" },
            { value: "GREATER_THAN", label: "Greater Than" },
            { value: "LESS_THAN_SUM", label: "Less than Sum" },
            { value: "GREATER_THAN_SUM", label: "Greater than Sum" },
            { value: "ONE_IS_LESS", label: "One is Less" },
            { value: "ONE_IS_GREATER", label: "One is Greater" },
            { value: "ALL_ARE_LESS", label: "All are Less" },
            { value: "ALL_ARE_GREATER", label: "All are Greater" },
            { value: "EQUAL_TO", label: "Equal to" }
        ];*/

        var branchTypes = [
            { value: "NO_BRANCH", label: "NO_BRANCH" },
            { value: "LESS_THAN", label: "LESS_THAN" },
            { value: "GREATER_THAN", label: "GREATER_THAN" },
            { value: "LESS_THAN_SUM", label: "LESS_THAN_SUM" },
            { value: "GREATER_THAN_SUM", label: "GREATER_THAN_SUM" },
            { value: "ONE_IS_LESS", label: "ONE_IS_LESS" },
            { value: "ONE_IS_GREATER", label: "ONE_IS_GREATER" },
            { value: "ALL_ARE_LESS", label: "ALL_ARE_LESS" },
            { value: "ALL_ARE_GREATER", label: "ALL_ARE_GREATER" },
            { value: "EQUAL_TO", label: "EQUAL_TO" }
        ];

        var mobileGraphingCategories = [
            { value: "NONE", label: "NONE" },
            { value: "MOOD", label: "MOOD" },
            { value: "BELIEFS", label: "BELIEFS" }
        ];

        var selBranchType = { value: this.state.branchType, label: this.state.branchType };
        var selMobileGraphingCategory = { value: this.state.mobileGraphingCategory, label: this.state.mobileGraphingCategory };
        for (i = 0; i < this.state.answers.length; i++) {
            answers.push(this.state.answers[i]);
        }

        this.state.dataItems = answers.map((el) => {
            var actions = <div className="actions-right">
                    <Button
                        onClick={() => {
                            this.state.dataItems = this.state.dataItems.filter(function( obj ) {
                                return obj.uuid !== el.uuid;
                            });

                            this.state.answers = this.state.answers.filter(function( obj ) {
                                return obj.uuid !== el.uuid;
                            });

                            //console.log("this.state.answers:", this.state.answers);
                            //console.log("dataitems:", dataItems);

                            this.setState({data: this.state});
                            this.validations();
                        }}
                        className="btn-icon btn-round"
                        color="danger"
                        size="sm">
                        <i className="fa fa-trash" />
                    </Button>
                    
                    {"  "}
                    <Button
                        onClick={() => {
                            this.state.changeText = el.text;
                            this.state.changeValue = el.value;
                            this.state.changeUuid = el.uuid;
                            this.state.changeType = "Edit";
                            this.state.indexAnswer = this.state.dataItems.map(e => e.uuid).indexOf(el.uuid);
                            
                            editAnswer();
                        }}
                        className="btn-icon btn-round"
                        color="warning"
                        size="sm">
                        <i className="fa fa-edit" />
                    </Button>
                    </div>;
            
            if(isStudyStarted()){
                actions = <div className="actions-right"></div>;
            }

            return {
              uuid: el.uuid,
              text: el.text,
              value: el.value,
    
              actions: actions
            };
        })

        var title = 'Create new question';
        if (this.state.id > 0) {
            if (this.state.readOnly){
                title = `View question ${this.state.id}`
            } else {
                title = `Edit question ${this.state.id}`
            }
        }

        const idValidationError = () => {
            if (this.state.validation.id === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The order number has to be greater than {this.state.min_id}, less than {this.state.max_id} and preferable not an already existing question number or it will mess up with branching.
                        </small>
                        </p>
                    </>);
            }
        }

        const textValidationError = () => {
            if (this.state.validation.text === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Question has to be more than 10 characters in length.
                        </small>
                        </p>
                    </>);
            }
        }

        const conceptValidationError = () => {
            if (this.state.validation.concept === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                You need to select a Concept for the Question.
                        </small>
                        </p>
                    </>);
            }
        }

        const leftLabelValidationError = () => {
            if (this.state.validation.leftLabel === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The labels cannot be more than 12 characters.
                        </small>
                        </p>
                    </>);
            }
        }

        const rightLabelValidationError = () => {
            if (this.state.validation.rightLabel === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The labels cannot be more than 12 characters.
                        </small>
                        </p>
                    </>);
            }
        }

        const answersValidationError = () => {
            if (this.state.validation.answerValues === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The "Answer Value Range" has to have a value between 2 and 10.
                        </small>
                        </p>
                    </>);
            }
        }

        const AnswerTextLenghtValidationError = () => {
            if (this.state.validation.changeText === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Answer cannot be less than 1 character in length and no more than 60 characters.
                        </small>
                        </p>
                    </>);
            }
        }

        const AnswerValueValidationError = () => {
            if (this.state.validation.changeValue === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Answer cannot have a value less than 0 nor greater than 100.
                        </small>
                        </p>
                    </>);
            }
        }

        const branchingValidationError = () => {
            if (this.state.validation.branching === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Branch needs valid values for "Branch Value" and "Number of Questions to Skip" if the "Branch Type" is different than NO_BRANCH.
                        </small>
                        </p>
                    </>);
            }
        }

        const branchValueValidationError = () => {
            if (this.state.validation.branchValue === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Branch Value cannot have a value less than 0 nor greater than 100.
                        </small>
                        </p>
                    </>);
            }
        }

        const numQuestionsToSkipValidationError = () => {
            if (this.state.validation.numQuestionsToSkip === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Number of Questions to Skip cannot have a value less than 0 nor greater than 100.
                        </small>
                        </p>
                    </>);
            }
        }

        const otherBranchIdsValidationError = () => {
            if (this.state.validation.otherBranchIds === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Other Branchs IDs need to have valid IDs.
                        </small>
                        </p>
                    </>);
            }
        }

        const rangeAnswersError = () => {
            if (this.state.validation.rangeAnswers === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                If a Range value is selected you need to set Left and Right Labels.
                        </small>
                        </p>
                    </>);
            }
        }

        const rangeOrOptionAnswersError = () => {
            if (this.state.validation.rangeOrOptionAnswers === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                You have to enter either Option Answers or Range Answer, not both.
                        </small>
                        </p>
                    </>);
            }
        }

        const numberOfAnswersError = () => {
            if (this.state.validation.numberOfAnswers === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Number of Answers has to between 2 and 5.
                        </small>
                        </p>
                    </>);
            }
        }

        const noAnswersError = () => {
            if (this.state.validation.noAnswers === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Questions need either more than one answer, or a valid range answer.
                        </small>
                        </p>
                    </>);
            }
        }

        

        const editAnswer =() => {
            if(this.state.changeType == "Add"){
                this.state.validation["changeText"] = "has-danger";
                this.state.validation["changeValue"] = "has-danger";
            } else {
                this.state.validation["changeText"] = "has-success";
                this.state.validation["changeValue"] = "has-success";
            }
            this.setState({ showDialog: 'shownDialog' });
        }
    
        const saveAnswer =() => {

            console.log("this.state.validation: ", this.state.validation);

            if (this.state.validation.changeValue === "has-danger" ||
                this.state.validation.changeText === "has-danger") {
                this.setState({
                    alert: (
                        <SweetAlert
                            error
                            style={{ display: "block", marginTop: "100px" }}
                            title="Something when wrong!"
                            onConfirm={() => this.hideAlert()}
                            onCancel={() => this.hideAlert()}
                            confirmBtnBsStyle="danger"
                        >
                            Please review the errors and try again.
                    </SweetAlert>
                    )
                });
            } else {
                console.log("saveAnswer");

                console.log("indexAnswer: ",this.state.indexAnswer);
                console.log("changeValue, changeText: ",this.state.changeValue, this.state.changeText);
                console.log("dataItems: ",this.state.dataItems);
                console.log("this.state.answers: ", this.state.answers);

                var answers = this.state.answers;

                function uuidv4() {
                    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                    );
                }
        
                if(this.state.changeType == "Add"){
                    var theAnswer = { uuid:"", text:"", value:-1};
                    theAnswer.uuid = uuidv4();
                    theAnswer.text = this.state.changeText;
                    theAnswer.value = parseInt(this.state.changeValue);

                    answers.push(theAnswer);
                } else {
                    var theAnswer = answers[this.state.indexAnswer];

                    theAnswer.text = this.state.changeText;
                    theAnswer.value = parseInt(this.state.changeValue);

                    answers[this.state.indexAnswer] = theAnswer;

                    console.log("dataItems[this.state.indexAnswer]: ",this.state.dataItems[this.state.indexAnswer]);
                    console.log("theAnswer: ",theAnswer);
                }

                this.setState({ answers: answers });
        
                hideAnswerPopup();
                this.validations();
            }
        }
    
        const cancelAnswer =() => {
            console.log("cancelAnswer");
            hideAnswerPopup();
        }

        const hideAnswerPopup =() => {
            console.log("hideAnswerPopup");
            this.setState({ showDialog: 'hiddenDialog' });
        }

        var buttonAdd = <Button color="info" className="btn-round btn-icon pull-right"
                            onClick={() => {
                                if(this.state.answers.length < 5 ){
                                    this.state.changeText = "";
                                    this.state.changeValue = "";
                                    this.state.changeType = "Add";
                                    this.state.indexAnswer = 0;
                                    console.log("Add Answer");
                                    editAnswer();
                                }
                            }}>
                            <i className="now-ui-icons ui-1_simple-add" />
                        </Button>;
        var buttonSubmit = <Button className="btn-fill pull-right" color="success" type="submit" onClick={this.saveQuestion.bind(this)}> Submit </Button>;

        if(isStudyStarted()){ 
            buttonAdd = "";
            buttonSubmit = "";
        }

        return (

            <>
                <div class={this.state.showDialog}>
                    <SweetAlert
                            showCancel
                            title={this.state.changeType+" Answer "}
                            onConfirm={() => saveAnswer()}
                            onCancel={() => cancelAnswer()}
                            dependencies={[this.state.changeText, this.state.changeValue]}
                        >
                            {(renderProps: SweetAlertRenderProps) => (
                                <form>
                                    
                                    {AnswerValueValidationError()}
                                    {AnswerTextLenghtValidationError()}
                                    <input
                                        id="changeText"
                                        name="changeText"
                                        type={'text'}
                                        ref={renderProps.setAutoFocusInputRef}
                                        className="form-control"
                                        value={this.state.changeText}
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={this.handleChange.bind(this)}
                                        placeholder={'Text'}
                                    />
                                    <br />
                                    <input
                                        id="changeValue"
                                        name="changeValue"
                                        type={'number'}
                                        className="form-control"
                                        value={this.state.changeValue}
                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                        onChange={this.handleChange.bind(this)}
                                        placeholder={'Value'}
                                    />
                                </form>
                            )}
                    </SweetAlert>
                </div>

                {this.state.alert}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Card>
                                        <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                        {buttonSubmit}
                                        {"  "}
                                        <Button className="btn-fill pull-right" color="danger" type="submit"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Card>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={7}>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <CardTitle tag="h5">Order Number</CardTitle>
                                                        {idValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.id}>
                                                            <Input type="number" name="id"
                                                                value={this.state.id}
                                                                onChange={this.handleChange.bind(this)} 
                                                                readOnly = {this.state.readOnly} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <CardTitle tag="h5">Question</CardTitle>
                                                        {textValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.text}>
                                                            <Input type="text" name="text"
                                                                value={this.state.text}
                                                                onChange={this.handleChange.bind(this)} 
                                                                readOnly = {this.state.readOnly} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <CardTitle tag="h5">Concept</CardTitle>
                                                        {conceptValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.concept}>
                                                        <Select
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select a concept"
                                                            name="concept"
                                                            isDisabled = {this.state.readOnly}
                                                            value={this.state.concept}
                                                            options={this.props.lconcepts}
                                                            onChange={this.handleConceptChange.bind(this)} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <CardTitle tag="h5">Mobile Graphing Category</CardTitle>
                                                        <FormGroup>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select a Mobile Graphing Category"
                                                                name="mobileGraphingCategory"
                                                                isDisabled = {this.state.readOnly}
                                                                value={selMobileGraphingCategory}
                                                                options={mobileGraphingCategories}
                                                                onChange={this.handleMobileGraphingCategory.bind(this)} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <CardTitle tag="h5">Branching</CardTitle>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <label>Branch Type</label>
                                                        <FormGroup>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select a Branch Type"
                                                                name="branchType"
                                                                isDisabled = {this.state.readOnly} 
                                                                value={selBranchType}
                                                                options={branchTypes}
                                                                onChange={this.handleBranchTypeChange.bind(this)} />
                                                        </FormGroup>
                                                    </Col>
                                                    {branchingValidationError()}
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <label>Branch Value</label>
                                                        {branchValueValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.branchValue}>
                                                            <Input type="number" name="branchValue"
                                                                ref="branchValue"
                                                                value={this.state.branchValue}
                                                                onChange={this.handleChange.bind(this)} 
                                                                readOnly = {this.state.readOnly} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <label>Number of Questions to Skip</label>
                                                        {numQuestionsToSkipValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.numQuestionsToSkip}>
                                                            <Input type="number" name="numQuestionsToSkip"
                                                                ref="numQuestionsToSkip"
                                                                value={this.state.numQuestionsToSkip}
                                                                onChange={this.handleChange.bind(this)} 
                                                                readOnly = {this.state.readOnly} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <label>Other Branchs IDs</label>
                                                        {otherBranchIdsValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.otherBranchIds}>
                                                            <Input type="text" name="otherBranchIds"
                                                                ref="otherBranchIds"
                                                                value={this.state.otherBranchIds}
                                                                onChange={this.handleChange.bind(this)} 
                                                                readOnly = {this.state.readOnly} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>

                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Row>
                                                    <Col xs={12} md={7}>
                                                        <Card>
                                                            <CardHeader>
                                                                {buttonAdd}
                                                                <CardTitle tag="h5">Option Answers</CardTitle>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <ReactTable
                                                                    data={this.state.dataItems}
                                                                    filterable
                                                                    columns={[
                                                                    {
                                                                        Header: "Text",
                                                                        accessor: "text",
                                                                        minWidth: 500
                                                                    },
                                                                    {
                                                                        Header: "Value",
                                                                        accessor: "value",
                                                                        style: {"text-align":"right"},
                                                                        maxWidth: 80
                                                                    },
                                                                    {
                                                                        Header: "Actions",
                                                                        accessor: "actions",
                                                                        sortable: false,
                                                                        filterable: false,
                                                                        maxWidth: 150 
                                                                    }
                                                                    ]}
                                                                    defaultPageSize={6}
                                                                    showPaginationBottom={false}
                                                                    className="-striped -highlight"
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} md={5}>
                                                        <Card>
                                                            <CardHeader>
                                                                <CardTitle tag="h5">Range Answers</CardTitle>
                                                            </CardHeader>
                                                            <CardBody>    
                                                                <Row>
                                                                    <Col xs={12} md={6}>
                                                                        <label>Range Value</label>
                                                                        {answersValidationError()}
                                                                        <FormGroup className={"has-label " + this.state.validation.answers}>
                                                                            <Input type="number" name="answerValues"
                                                                                value={this.state.answerValues}
                                                                                onChange={this.handleChange.bind(this)} 
                                                                                readOnly = {this.state.readOnly} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs={12} md={6}>
                                                                        <FormGroup check className="mt-3">
                                                                            <Label check>
                                                                                <Input type="checkbox" name="reverseResult"
                                                                                    checked={this.state.reverseResult}
                                                                                    disabled = {this.state.readOnly}
                                                                                    onChange={() => this.setState({ reverseResult: !this.state.reverseResult })} />
                                                                                <span className="form-check-sign" /> 
                                                                                Reverse Result
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    {rangeAnswersError()}
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={12} md={6}>
                                                                        <label>Left Label</label>
                                                                        {leftLabelValidationError()}
                                                                        <FormGroup>
                                                                            <Input type="text" name="leftLabel"
                                                                                value={this.state.leftLabel}
                                                                                onChange={this.handleChange.bind(this)} 
                                                                                readOnly = {this.state.readOnly} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs={12} md={6}>
                                                                        <label>Right Label</label>
                                                                        {rightLabelValidationError()}
                                                                        <FormGroup>
                                                                            <Input type="text" name="rightLabel"
                                                                                value={this.state.rightLabel}
                                                                                onChange={this.handleChange.bind(this)} 
                                                                                readOnly = {this.state.readOnly} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody> 
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                {numberOfAnswersError()}
                                                {rangeOrOptionAnswersError()}
                                                {noAnswersError()}
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>

                                <CardFooter className="pull-right">

                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { items, questionsLoading } = state.questions;
    const { concepts } = state.concepts;

    //console.log("mapState concepts:", concepts);
    var lconcepts = [];
    if (concepts) {
        lconcepts = concepts.map((c) => {
            return {
                value: c.id,
                label: c.name
            };
        });
    }

    return { items, lconcepts, questionsLoading };
}

const actionCreators = {
    getAll: questionsActions.getAll,
    getConcepts: conceptsActions.getAll
};

const connectedQuestionsEdit = connect(mapState, actionCreators)(QuestionsEdit);
export { connectedQuestionsEdit as QuestionsEdit }; 