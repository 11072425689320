import { genericConstants } from '../constants';
import { questionsService} from '../services'; 
import { alertActions } from '.';

export const questionsActions = {
    getAll,
    getCOREQuestions,
    getSelfHarm
};

function getAll() {
    
    return dispatch => {
        dispatch(request());

        console.log("questionsActions.getAllQuestions");
        questionsService.getAllQuestions()
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.QUESTIONS_REQUEST } }
    function success(items) { return { type: genericConstants.QUESTIONS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.QUESTIONS_FAILURE, error } }
}

function getCOREQuestions() {
    
    return dispatch => {
        dispatch(request());

        console.log("questionsActions.getCOREQuestions");
        questionsService.getQuestionsByForm("00bf350c-126a-4b3e-a17a-951c44cbdd2b")
            .then(
                response => {
                    //console.log("questionsActions.response", response.data);
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.QUESTIONS_REQUEST } }
    function success(items) { return { type: genericConstants.QUESTIONS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.QUESTIONS_FAILURE, error } }
}

function getSelfHarm() {
    
    return dispatch => {
        dispatch(request());

        console.log("questionsActions.getSelfHarm");
        questionsService.getQuestionsByForm("b20fceaf-27ae-43b2-a60a-5f4e048dfdab")
            .then(
                response => {
                    //console.log("questionsActions.response", response.data);
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.QUESTIONS_REQUEST } }
    function success(items) { return { type: genericConstants.QUESTIONS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.QUESTIONS_FAILURE, error } }
}