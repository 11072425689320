export function authHeader(tk) {
    // return authorization header with jwt token
    var token = "";
    if (tk === undefined) {
        token = JSON.parse(localStorage.getItem('usertoken'));
    } else {
        token = tk;
    }


    if (token && token.length > 0) {
        return {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json; charset=utf8'
        };
    } else {
        return {};
    }
}

