/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Form, Input, Label, Button } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { isStudyStarted, updateInstallStatus } from '../../helpers';
import { providersActions, lookupActions, participantsActions } from '../../actions';
import { participantsService } from '../../services/data.service';
import { proxysService } from '../../services/data.service';


class ParticipantsEdit extends Component {
    constructor(props) {
        super(props);
        var pid = props.match.params.pid;
        this.state = {
            alert: null,
            show: false,
            lforms: [],
            sformsfull: [],
            lformsfull: [],
            id: pid,
            data: [],
            district: '',
            completed: false,
            password: '',
            passwordVerf: '',
            mobileCode: '',
            mobileCodeVerf: '',
            isAdd: false,
            mappstarted: false,
            pdelusion: null,
            sdelusion: null,
            includeSelfHarmQuestionnaire: false,
            provider: null,
            validation: {
                district: 'has-danger',
                provider: 'has-danger',
                mobileCode: 'has-danger',
                samePrimaryAndSecondaryDelusion: ''
            }
        };

        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlert1 = this.hideAlert1.bind(this);

        if (props.lforms === undefined || props.lforms === null || props.lforms.length === 0) {
            props.getForms();
        }

        if (props.lproviders === undefined || props.lproviders === null || props.lproviders.length === 0) {
            props.getProviders();
        }

        if (props.concepts === undefined || props.concepts === null || props.concepts.length === 0) {
            props.getConcepts();
        }

        if (props.location.state && props.location.state.theParticipant) {
            var p = props.location.state.theParticipant;

            if (p && p !== null) {
                this.state = {
                    ...this.state,
                    district: p.user.login,
                    password: 'j12k!2l4m5*',
                    passwordVerf: 'j12k!2l4m5*',
                    mobileCode: p.user.mobileCode,
                    mobileCodeVerf: p.user.mobileCode,
                    completed: p.completed,
                    mappstarted: false,
                    validation: {
                        district: '',
                        provider: '',
                        mobileCode: '',
                        samePrimaryAndSecondaryDelusion: ''
                    }
                };
                if (p.provider && p.provider !== null) {
                    this.state.provider = {
                        value: p.provider.id,
                        label: p.provider.user.login
                    };
                }

                if (p.alertConfig && p.alertConfig !== null) {
                    this.state.mappstarted = p.alertConfig.mobileAppStarted;
                    if (p.alertConfig.primaryDelusionForm && p.alertConfig.primaryDelusionForm !== null) {
                        this.state.pdelusion = {
                            value: p.alertConfig.primaryDelusionForm.id,
                            label: p.alertConfig.primaryDelusionForm.name //label: p.alertConfig.primaryDelusionForm.questions[0].text
                        };
                    }
                    if (p.alertConfig.secondaryDelusionForm && p.alertConfig.secondaryDelusionForm !== null) {
                        this.state.sdelusion = {
                            value: p.alertConfig.secondaryDelusionForm.id,
                            label: p.alertConfig.secondaryDelusionForm.name //label: p.alertConfig.secondaryDelusionForm.questions[0].text
                        };
                    }
                    if (p.alertConfig.alertForms && p.alertConfig.alertForms.length > 0) {
                        for (let i = 0; i < p.alertConfig.alertForms.length; i++) {
                            const form = p.alertConfig.alertForms[i];
                            if (form.type === "SELF_HARM") {
                                this.state.includeSelfHarmQuestionnaire = true;
                                break;
                            }
                        }
                    }

                }
            } else {
                this.props.history.goBack();
            }
        } else {
            this.state.isAdd = true;
        }
    }


    componentWillUnmount() {
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        switch (name) {
            case 'district':
                if (value.length >= 7) {
                    this.state.validation["district"] = "has-success";
                } else {
                    this.state.validation["district"] = "has-danger";
                }
                break;
            default:
                break;
        }

/*
        switch (name) {
            case 'provider':
                if (value != 5) {
                    this.state.validation["provider"] = "has-success";
                } else {
                    this.state.validation["provider"] = "has-danger";
                }
                break;
            default:
                break;
        }*/

        switch (name) {
            case 'mobileCode':
                if (value.length == 7) {
                    this.state.validation["mobileCode"] = "has-success";
                } else {
                    this.state.validation["mobileCode"] = "has-danger";
                }
                break;
            default:
                break;
        }
    }

    handleProviderChange(value) {
        this.setState({ provider: value })
        this.setState({
            validation: {
                ...this.state.validation,
                provider: "has-success"
            }
        }); 
    }
    handlePDelusionChange(value) {
        if(this.state.sdelusion){
            if(this.state.sdelusion.value != value.value){
                this.state.validation["samePrimaryAndSecondaryDelusion"] = "has-success";
            } else {
                this.state.validation["samePrimaryAndSecondaryDelusion"] = "has-danger";
            }
        } else {
            this.state.validation["samePrimaryAndSecondaryDelusion"] = "has-success";
        }
        //console.log("samePrimaryAndSecondaryDelusion: ", this.state.validation["samePrimaryAndSecondaryDelusion"], value.value);
        this.setState({ pdelusion: value })
    }
    handleSDelusionChange(value) {
        if(this.state.pdelusion){
            if(this.state.pdelusion.value != value.value){
                this.state.validation["samePrimaryAndSecondaryDelusion"] = "has-success";
            } else {
                this.state.validation["samePrimaryAndSecondaryDelusion"] = "has-danger";
            }
        } else {
            this.state.validation["samePrimaryAndSecondaryDelusion"] = "has-success";
        }
        //console.log("samePrimaryAndSecondaryDelusion: ", this.state.validation["samePrimaryAndSecondaryDelusion"], value.value);
        this.setState({ sdelusion: value })
    }

    saveParticipant() {
        if (this.state.validation.district === "has-danger" || this.state.validation.provider === "has-danger" || this.state.validation.mobileCode === "has-danger" || this.state.validation.samePrimaryAndSecondaryDelusion === "has-danger") {
            this.setState({
                alert: (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "100px" }}
                        title="Something when wrong!"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                    >
                        Please review the errors tagged in red and try again.
                  </SweetAlert>
                )
            });
        } else {
            var theParticipant = {};

            if (this.state.isAdd !== true) {
                theParticipant = this.props.location.state.theParticipant;
            } else {
                theParticipant.user = {};
                theParticipant.provider = {};
                theParticipant.alertConfig = {
                    useDefaultBaseline: true,
                    conceptWeights: null,
                    primaryDelusionForm: null,
                    secondaryDelusionForm: null,
                    alertForms: []
                };

            }
           
            theParticipant.user.password = this.state.mobileCode + 'j12k!2l4m5*';
            theParticipant.user.login = this.state.district
            theParticipant.user.mobileCode = this.state.mobileCode
            theParticipant.user.role = "ROLE_PARTICIPANT";
            theParticipant.completed = this.state.completed;
            theParticipant.provider.id = this.state.provider.value;

            const currentURL = window.location.href; // returns the absolute URL of a page
            const pathname = window.location.pathname; //returns the current url minus the domain name
            const server = currentURL.replace(pathname,'');

            console.log("SERVER:", server);

            if(this.state.mobileCode != this.state.mobileCodeVerf){
                var proxyData = {
                    mobileCode: theParticipant.user.mobileCode,
                    user: theParticipant.user.login,
                    server: server, // process.env.REACT_APP_API_URL,
                    dateCreated: null,
                    dateConnected: null
                }
                proxysService.addUserProxy(proxyData);
            }

            console.log("The Participant: ", theParticipant);
            console.log("this.state.pdelusion: ", this.state.pdelusion);
            console.log("this.state.sdelusion: ", this.state.sdelusion);
            console.log("this.state.sdelusion: ", this.state.sdelusion);

            if (this.state.pdelusion && this.state.pdelusion.value.length > 0) {
                theParticipant.alertConfig.primaryDelusionForm = this.state.lformsfull.find((lf) => lf.id === this.state.pdelusion.value)
            }

            if (this.state.sdelusion && this.state.sdelusion.value.length > 0) {
                theParticipant.alertConfig.secondaryDelusionForm = this.state.lformsfull.find((lf) => lf.id === this.state.sdelusion.value)
            }

            console.log("this.state.lformsfull: ", this.state.lformsfull);
            console.log("The Participant: ", theParticipant);

            if (this.state.includeSelfHarmQuestionnaire === true && this.state.sformsfull) {
                for (var i = 0; i < this.state.sformsfull.length; i++) {
                    // Add if not already present
                    var alreadyPresent = false;
                    for (var j = 0; j < theParticipant.alertConfig.alertForms.length; j++) {
                        if (theParticipant.alertConfig.alertForms[j].id === this.state.sformsfull[i].id) {
                            alreadyPresent = true;
                            break;
                        }
                    }
                    if (!alreadyPresent) {
                        theParticipant.alertConfig.alertForms.push(this.state.sformsfull[i]);
                    }
                }
            } else {
                var tmpForms = [];
                for (var j = 0; j < theParticipant.alertConfig.alertForms.length; j++) {
                    if (theParticipant.alertConfig.alertForms[j].type !== "SELF_HARM") {
                        tmpForms.push(theParticipant.alertConfig.alertForms[j]);
                    }
                }
                theParticipant.alertConfig.alertForms = tmpForms;
            }

            if (this.state.isAdd !== true) {
                participantsService.saveParticipant(theParticipant, theParticipant.id).then(r => {
                    console.log("ParticipantsEdit.saveParticipant: ", r);
                    if(r){ 
                        if(isStudyStarted()){
                            updateInstallStatus();
                        }
                        this.setState({
                            alert: (
                                <SweetAlert
                                    success
                                    style={{ display: "block", marginTop: "100px" }}
                                    title="Participant Saved"
                                    onConfirm={() =>
                                        this.hideAlert1()
                                    }
                                    onCancel={() => this.hideAlert1()}
                                    confirmBtnBsStyle="info"
                                >

                                </SweetAlert>
                            )
                        });                        
                    } else {
                        this.setState({
                            alert: (
                                <SweetAlert
                                    error
                                    style={{ display: "block", marginTop: "100px" }}
                                    title="Something when wrong!"
                                    onConfirm={() => this.hideAlert()}
                                    onCancel={() => this.hideAlert()}
                                    confirmBtnBsStyle="danger"
                                >
                                    There was an error saving the Participant. Check if it doesn't already exist.
                            </SweetAlert>
                            )
                        });
                    }
                }
                );
            } else {
                participantsService.addParticipant(theParticipant).then(r => {
                    console.log("ParticipantsEdit.addParticipant: ", r);
                    if(r){ 
                        this.setState({
                            alert: (
                                <SweetAlert
                                    success
                                    style={{ display: "block", marginTop: "100px" }}
                                    title="Participant Saved"
                                    onConfirm={() => this.hideAlert1()}
                                    onCancel={() => this.hideAlert1()}
                                    confirmBtnBsStyle="info"
                                >
                                </SweetAlert>
                            )
                        });
                    } else {
                        this.setState({
                            alert: (
                                <SweetAlert
                                    error
                                    style={{ display: "block", marginTop: "100px" }}
                                    title="Something when wrong!"
                                    onConfirm={() => this.hideAlert()}
                                    onCancel={() => this.hideAlert()}
                                    confirmBtnBsStyle="danger"
                                >
                                    There was an error saving the Participant. Check if it doesn't already exist.
                            </SweetAlert>
                            )
                        });                        
                    }
                }
                );
            }
        }
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    hideAlert1() {
        this.setState({
            alert: null
        });

        this.props.history.goBack();
    }

    render() {
        var title = 'Create new participant';
        if (this.state.id > 0) {
            title = `Edit participant ${this.state.district}`
        }

        if(this.props.lforms.length > 0){
            this.state.lforms = this.props.lforms;
            this.state.sforms = this.props.sforms;
            this.state.lformsfull = this.props.lformsfull;
            this.state.sformsfull = this.props.sformsfull;
        }

        /*
        console.log("this.props.concepts/lproviders/lforms/this.state.lforms: ", 
            this.props.concepts != undefined ? this.props.concepts.length : 0,
            this.props.lproviders != undefined ? this.props.lproviders.length : 0,
            this.props.lforms != undefined ? this.props.lforms.length : 0,
            this.state.lforms != undefined ? this.state.lforms.length : 0);

        console.log("plforms, slforms: ", this.state.plforms, this.state.slforms);
        console.log("this.state.pdelusion: ", this.state.pdelusion);
 
        if (this.state.pdelusion && this.state.pdelusion.length > 0) {
            slforms = this.props.lforms.filter((item) => {
                return item.value !== this.state.pdelusion;
            });
        }


        if (this.state.sdelusion && this.state.sdelusion.length > 0) {
            plforms = this.props.lforms.filter((item) => {
                return item.value !== this.state.sdelusion;
            });
        }*/

        const districValidationError = () => {
            if (this.state.validation.district === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Participant Id must be unique and at least 5 characters
                        </small>
                        </p>
                    </>);
            }
        }

        const providerValidationError = () => {
            if (this.state.validation.provider === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Please select a provider
                        </small>
                        </p>
                    </>);
            }
        }

        const mobileCodeValidationError = () => {
            if (this.state.validation.mobileCode === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Please enter a valid mobile code.
                        </small>
                        </p>
                    </>);
            }
        }

        const districtNewParticipantAlert = () => {
            if (this.state.isAdd === true) {
                return (
                    <>
                        <p className="text-info">
                            <small>
                                The Participant Id provides the link to the patient in your patient record system. Please enter it with care.
                        </small>
                        </p>
                    </>);
            }
        }

        const samePrimaryAndSecondaryDelusionError = () => {
            if (this.state.validation.samePrimaryAndSecondaryDelusion === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Primary and Secondary delusion questionnaires cannot be the same.
                        </small>
                        </p>
                    </>);
            }
        }

        return (
            < >
                {this.state.alert}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Card>
                                        <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                        <Button className="btn-fill pull-right" color="success" type="submit"
                                            onClick={this.saveParticipant.bind(this)}>
                                            Submit
                                        </Button>
                                        {"  "}
                                        <Button className="btn-fill pull-right" color="danger" type="submit"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}>
                                            Cancel
                                        </Button>
                                    </Card>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <CardTitle tag="h5">User Details</CardTitle>
                                                <label>Participant Id</label>
                                                {districtNewParticipantAlert()}
                                                {districValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.district}>
                                                    <Input type="text" name="district"
                                                        value={this.state.district}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <CardTitle tag="h5">Participant Details</CardTitle>
                                                <label>Provider</label>
                                                {providerValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.provider}>
                                                    <Select
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select a provider"
                                                        name="provider"
                                                        value={this.state.provider}
                                                        options={this.props.lproviders}
                                                        onChange={this.handleProviderChange.bind(this)} />
                                                </FormGroup>
                                                <FormGroup check className="mt-3">
                                                    <Label check>
                                                        <Input type="checkbox" name="completed"
                                                            checked={this.state.completed}
                                                            onChange={() => this.setState({ completed: !this.state.completed })} />
                                                        <span className="form-check-sign" />
                                                        Mark as completed?
                                                    </Label>
                                                </FormGroup>
                                                <br /><br />
                                                <label>Mobile Code</label>
                                                {mobileCodeValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.mobileCode}>
                                                    <Input type="text" name="mobileCode"
                                                        value={this.state.mobileCode}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <CardTitle tag="h5">Delusions</CardTitle>
                                                <p className="category text-warning">
                                                    Delusion cannot be altered after participant has started using the app
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <label>Primary Delusion</label>
                                                <FormGroup>
                                                    <Select
                                                        isDisabled={this.state.mappstarted}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select a Delusion"
                                                        name="pdelusion"
                                                        value={this.state.pdelusion}
                                                        options={this.state.lforms}
                                                        onChange={this.handlePDelusionChange.bind(this)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <label>Secondary Delusion</label>
                                                <FormGroup>
                                                    <Select
                                                        isDisabled={this.state.mappstarted}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select a Delusion"
                                                        name="sdelusion"
                                                        value={this.state.sdelusion}
                                                        options={this.state.lforms}
                                                        onChange={this.handleSDelusionChange.bind(this)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                {samePrimaryAndSecondaryDelusionError()}
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <CardTitle tag="h5">Self Harm Questionnaire</CardTitle>
                                                <p className="category text-warning">
                                                The Self Harm Questionnaire cannot be altered after participant has started using the app
                                                </p>
                                                <FormGroup check className="mt-3">
                                                    <Label check>
                                                        <Input type="checkbox" name="includeSelfHarmQuestionnaire"
                                                            disabled={this.state.mappstarted}
                                                            checked={this.state.includeSelfHarmQuestionnaire}
                                                            onChange={() => this.setState({ includeSelfHarmQuestionnaire: !this.state.includeSelfHarmQuestionnaire })} />
                                                        <span className="form-check-sign" />
                                                        Include Self Harm questionnaire for this participant?
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>

                                <CardFooter className="pull-right">
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapState(state) {
//    const { participants, participantsLoading } = state.participants;
    const { providers } = state.providers;
    const { forms, concepts, lookupsLoading } = state.lookups;

    var lproviders = [];
    if (providers) {
        lproviders = providers.map((pv) => {
            return {
                value: pv.id,
                label: pv.user.login
            };
        });
    }
    
    var lforms = [];
    var lformsfull = [];
    var sforms = [];
    var sformsfull = [];
    if (forms) {
        for (let index = 0; index < forms.length; index++) {
            const form = forms[index];
            if (form.type === "DELUSIONS") {
                lformsfull.push(form);
                lforms.push({
                    value: form.id,
                    label: form.name //label: form.questions[0].text
                });

            } else {
                if (form.type === "SELF_HARM") {
                    sformsfull.push(form);
                    sforms.push({
                        value: form.id,
                        label: form.name //label: form.questions[0].text
                    });
                }
            }
        }
    }
    
    if (concepts) {
        for (var i = 0; i < concepts.length; i++) {
            concepts[i].selectedWeight = "";
        }
    }

    //console.log("mapState providers, forms, concepts", providers != undefined ? providers.length : 0 , forms != undefined ? forms.length : 0 , concepts != undefined ? concepts.length : 0);

    return { lproviders, concepts, lforms, sforms, sformsfull, lformsfull, lookupsLoading }; //participants, participantsLoading, 
}

const actionCreators = {
    getParticipant: participantsActions.getParticipant,
    getProviders: providersActions.getAll,
    getForms: lookupActions.getForms,
    getConcepts: lookupActions.getConcepts
};

const connectedParticipantsEdit = connect(mapState, actionCreators)(ParticipantsEdit);
export { connectedParticipantsEdit as ParticipantsEdit }; 