
import { genericConstants } from '../constants';
 
const initialState = {   
    answersLoading:false, 
    error:""
} ;

export function answers(state = initialState, action) {
    switch (action.type) {
        case genericConstants.ANSWERS_REQUEST:
            return {
                answersLoading: true
            };

        case genericConstants.ANSWERS_SUCCESS:
            return { 
                answersLoading:false,
                answers: action.items,
                error:""
            };

        case genericConstants.ANSWERS_FAILURE:
            return {
                answersLoading:false,
                answers: [], 
                error:action.error
            }; 

        default:
            return state
    }
}