/*eslint-disable*/
import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { providersActions } from '../../actions';

class Providers extends Component {
  constructor(props) {
    super(props);
    this.props.getAll();
    this.state = {
      data: []
    };
  }

  render() {
    const { providers } = this.props;

    if (providers && providers.length > 0) {
      var dataItems = providers.map((el) => {
        return {
          id: el.id,
          username: el.user.login,
          email: el.user.email,
          title: el.user.title,
          fname: el.user.firstName,
          lname: el.user.lastName,
          ptype: el.user.role,
          actions: (
            <div className="actions-right">
              <Button
                onClick={() => {
                  this.props.history.push({
                    pathname: `/admin/providers/${el.id}/edit`,
                    state: { theProvider: el }
                  });
                }}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fa fa-edit" />
              </Button>
            </div>
          )
        };
      });
    }

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
          row[id] !== undefined ?
              String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          :
              true
      );
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Button color="info" className="btn-round btn-icon pull-right"
                    onClick={() => {
                      this.props.history.push("/admin/providers/0/edit");
                    }}>
                    <i className="now-ui-icons ui-1_simple-add" />
                  </Button>
                  <CardTitle tag="h4">Providers</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={dataItems}
                    filterable
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
                    columns={[
                      {
                        Header: "Username",
                        accessor: "username"
                      },
                      {
                        Header: "Email",
                        accessor: "email"
                      },
                      {
                        Header: "Title",
                        accessor: "title"
                      },
                      {
                        Header: "First Name",
                        accessor: "fname"
                      },
                      {
                        Header: "Last Name",
                        accessor: "lname"
                      },
                      {
                        Header: "Prov. Type",
                        accessor: "ptype"
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { providers } = state.providers;
  return { providers };
}

const actionCreators = {
  getAll: providersActions.getAll
};

const connectedProviders = connect(mapState, actionCreators)(Providers);
export { connectedProviders as Providers }; 