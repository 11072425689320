/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Form, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { settingsActions } from '../../actions';
import { settingsService } from '../../services';

class SettingsEdit extends Component {
    constructor(props) {
        super(props);
        var pid = props.match.params.pid;
        this.state = {
            alert: null,
            show: false,
            id: pid,
            data: [],
            isAdd: false,
            validation: {
                value: 'has-danger'
            },
            code: '',
            title: '',
            value: '',
            valueType: '',
            valueMax: '',
            valueMin: '',
            uom: '',
        };

        if (props.location.state && props.location.state.theSetting) {
            var p = props.location.state.theSetting;
            if (p && p !== null) {
                this.state.valueType = p.valueType;
                if (p.valueType.toLowerCase() === "boolean"){
                    this.state.value = p.value === "true"? true : false;
                } else {
                    this.state.value = p.value;
                }                
                this.state.code = p.code;
                this.state.title = p.title;
                
                this.state.valueMax = p.valueMax;
                this.state.valueMin = p.valueMin;
                this.state.uom = p.uom;
                this.state.validation = {
                    value: ''
                }
            } else {
                this.props.history.goBack();
            }
        } else {
            this.state.isAdd = true;
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        switch (name) {
            case 'value':
                if(this.state.valueType.toLowerCase() === "int" || this.state.valueType.toLowerCase() === "integer"){
                    if (Number(value) >= this.state.valueMin && Number(value) <= this.state.valueMax) {
                        this.state.validation["value"] = "has-success";
                    } else {
                        this.state.validation["value"] = "has-danger";
                    }
                    break;
                } else {
                    if (value.length >= 1) {
                        this.state.validation["value"] = "has-success";
                    } else {
                        this.state.validation["value"] = "has-danger";
                    }
                    break;
                }

            default:
                break;
        }
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    hideAlert1() {
        this.setState({ alert: null });
        this.props.history.goBack();
    }

    saveSetting() {
        if (this.state.validation.value === "has-danger") {
            this.setState({
                alert: (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "100px" }}
                        title="Wrong Value!"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                    >
                        Please review the errors and try again.
                  </SweetAlert>
                )
            });
        } else {
            var theSetting = {};

            if (this.state.isAdd !== true) {
                theSetting = this.props.location.state.theSetting;
            }

            theSetting.value = this.state.value;
        }

        if (this.state.isAdd !== true) {
            settingsService.saveSetting(theSetting, theSetting.id).then(r => {
                this.setState({
                    alert: (
                        <SweetAlert
                            success
                            style={{ display: "block", marginTop: "100px" }}
                            title="Setting Saved"
                            onConfirm={() => this.hideAlert1()}
                            onCancel={() => this.hideAlert1()}
                            confirmBtnBsStyle="info"
                        >
                        </SweetAlert>
                    )
                });

            }
            );
        } else {
            settingsService.addSetting(theSetting).then(r => {
                this.setState({
                    alert: (
                        <SweetAlert
                            success
                            style={{ display: "block", marginTop: "100px" }}
                            title="Setting Saved"
                            onConfirm={() => this.hideAlert1()}
                            onCancel={() => this.hideAlert1()}
                            confirmBtnBsStyle="info"
                        >
                        </SweetAlert>
                    )
                });
            }
            );
        }
    }

    render() {
        //const { items, participantsLoading } = this.props;
        var title = 'Create new setting';
        if (this.state.id > 0) {
            title = `Edit ${this.state.title}`
        }


        const valueValidationError = () => {
            if (this.state.validation.value === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Valid values are between {this.state.valueMin} and {this.state.valueMax}
                        </small>
                        </p>
                    </>);
            }
        }

        var inputType = '';


        switch (this.state.valueType.toLowerCase()) {
            case 'int':
            case 'integer':
                inputType = 'number';
                
                break;
            case 'bool':
            case 'boolean':
            case 'bit':
                inputType = 'checkbox';
                break;
            case 'string':
            case 'text':
                inputType = 'text';
                break;
            default:
                inputType = 'text';
                break;
        }

        if(inputType=='checkbox'){
            return (

                <>
                    {this.state.alert}
                    <PanelHeader size="sm" />
                    <div className="content">
                        <Row>
                            <Col xs={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <Card>
                                            <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                            <Button className="btn-fill pull-right" color="success" type="submit"
                                                onClick={this.saveSetting.bind(this)}>
                                                Submit
                                            </Button>
                                            {"  "}
                                            <Button className="btn-fill pull-right" color="danger" type="submit"
                                                onClick={() => {
                                                    this.props.history.goBack();
                                                }}>
                                                Cancel
                                            </Button>
                                        </Card>
                                    </CardHeader>
                                    <CardBody>
                                        <Form action="#" method="#">
                                            <Row>
                                                <Col xs={6} md={3}>
                                                    <FormGroup check className="mt-3">
                                                        <Label check>
                                                            <Input type="checkbox" name="value"
                                                                checked={this.state.value}
                                                                onChange={() => this.setState({ value: !this.state.value })} />
                                                            <span className="form-check-sign" /> 
                                                            {this.state.title}
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>

                                    <CardFooter className="pull-right">
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            );
        }
        else
        {
            return (

                <>
                    {this.state.alert}
                    <PanelHeader size="sm" />
                    <div className="content">
                        <Row>
                            <Col xs={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <Card>
                                            <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                            <Button className="btn-fill pull-right" color="success" type="submit"
                                                onClick={this.saveSetting.bind(this)}>
                                                Submit
                                            </Button>
                                            {"  "}
                                            <Button className="btn-fill pull-right" color="danger" type="submit"
                                                onClick={() => {
                                                    this.props.history.goBack();
                                                }}>
                                                Cancel
                                            </Button>
                                        </Card>
                                    </CardHeader>
                                    <CardBody>
                                        <Form action="#" method="#">
                                            <Row>
                                                <Col xs={6} md={3}>
                                                    <label>{this.state.uom}</label>
                                                    {valueValidationError()}
                                                    <FormGroup>
                                                        <Input type={inputType} name="value"
                                                            value={this.state.value}
                                                            onChange={this.handleChange.bind(this)} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>

                                    <CardFooter className="pull-right">
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            );
        }
    }
}

function mapState(state) {
    const { items, settingsLoading } = state.settings;
    return { items, settingsLoading };
}

const actionCreators = {
    getAll: settingsActions.getAll
};

const connectedSettingsEdit = connect(mapState, actionCreators)(SettingsEdit);
export { connectedSettingsEdit as SettingsEdit }; 