import { genericConstants } from '../constants';
import { providersService } from '../services';
import { alertActions } from '.'; 

export const providersActions = {
    getAll
};

function getAll() {
    return dispatch => {
        dispatch(request());

        providersService.getAllProviders()
            .then(
                response => { 
                    dispatch(success(response.data)); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.PROVIDERS_REQUEST } }
    function success(items) { return { type: genericConstants.PROVIDERS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.PROVIDERS_FAILURE, error } }
}
