import { genericConstants } from '../constants';

let uToken = JSON.parse(localStorage.getItem('usertoken'));
let loggedIn = uToken ==null? false : uToken.length > 10;
  
const initialState = {  
    loggingIn:false,
    loggedIn: loggedIn,
    userToken: uToken,
    error:""
} ;

export function authentication(state = initialState, action) {
    switch (action.type) {
        case genericConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                username: action.username, 
            };

        case genericConstants.LOGIN_SUCCESS:
            return {
                loggingIn:false,
                loggedIn: true,
                userToken: action.userToken,
                error:""
            };

        case genericConstants.LOGIN_FAILURE:
            return {
                loggingIn:false,
                loggedIn: false,
                userToken: null,
                error:action.error
            };

        case genericConstants.LOGOUT:
            return {
                loggingIn:false,
                loggedIn: false,
                userToken: null,
                error:""
            };

        default:
            return state
    }
}