/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Form } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { overviewstatsActions } from '../../actions';

class Overviewstats extends Component {
    constructor(props) {
        super(props);

        if(props.location.pathname === "/admin/stats"){
          this.props.getAll();
        }else{
          var myId = window.localStorage.getItem("id");
          this.props.getAllMy(myId);
        }
        this.state = {
            totalNoOfParticipantsWithEncounters: '',
            totalNoOfParticipants: '',
            totalNoOfEncounters: '',
            totalNoOfPartialEncounters: '',
            totalNoOfCompleteEncounters: '',
            totalNoOfMissingEncounters: '',
            lastEncounter: '',
            data: []
        };
    }

    render() {
        const { overviewstats, overviewstatsLoading} = this.props;
        const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'numeric', minute:'numeric' };

        if (overviewstats != undefined && overviewstats != null) {
            this.state.totalNoOfParticipantsWithEncounters= overviewstats.totalNoOfParticipantsWithEncounters;
            this.state.totalNoOfParticipants= overviewstats.totalNoOfParticipants;
            this.state.totalNoOfEncounters= overviewstats.totalNoOfEncounters;
            this.state.totalNoOfPartialEncounters= overviewstats.totalNoOfPartialEncounters;
            this.state.totalNoOfCompleteEncounters= overviewstats.totalNoOfCompleteEncounters;
            this.state.totalNoOfMissingEncounters= overviewstats.totalNoOfMissingEncounters
            if(overviewstats.lastEncounter != null){
                var lastEnc = new Date(overviewstats.lastEncounter);
                this.state.lastEncounter= lastEnc.toLocaleDateString("en-UK", dateOptions);
            } else {
                this.state.lastEncounter= "";
            }
        }

        return (

            <>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Overview Stats</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <CardTitle tag="h6">Encounters</CardTitle>
                                                <Row>
                                                    <Col xs={3} md={3}>
                                                        <label>Total: </label>
                                                        <div class="overview-stat-item">{this.state.totalNoOfEncounters}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label>Completed: </label>
                                                        <div class="overview-stat-item">{this.state.totalNoOfCompleteEncounters}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label>Partial: </label>
                                                        <div class="overview-stat-item">{this.state.totalNoOfPartialEncounters}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label>Missed: </label>
                                                        <div class="overview-stat-item">{this.state.totalNoOfMissingEncounters}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <br/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={4} md={4}>
                                                        <label>Last Encounter: </label>
                                                        <div class="overview-stat-item">{this.state.lastEncounter}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <br/><br/>
                                                    </Col>
                                                </Row>
                                                <CardTitle tag="h6">Participants</CardTitle>
                                                <Row>
                                                    <Col xs={4} md={4}>
                                                        <label>Total: </label>
                                                        <div class="overview-stat-item">{this.state.totalNoOfParticipants}</div>
                                                    </Col>
                                                    <Col xs={4} md={4}>
                                                        <label>With Encounters: </label>
                                                        <div class="overview-stat-item">{this.state.totalNoOfParticipantsWithEncounters}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <br/><br/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


function mapState(state) {
    const { overviewstats, overviewstatsLoading } = state.overviewstats;
    return { overviewstats };
}

const actionCreators = {
    getAll: overviewstatsActions.getAll,
    getAllMy: overviewstatsActions.getAllMy
};

const connectedOverviewstats = connect(mapState, actionCreators)(Overviewstats);
export { connectedOverviewstats as Overviewstats }; 