
import { genericConstants } from '../constants';
 
const initialState = {   
    questionsLoading:false, 
    error:""
} ;

export function questions(state = initialState, action) {
    switch (action.type) {
        case genericConstants.QUESTIONS_REQUEST:
            return {
                questionsLoading: true
            };

        case genericConstants.QUESTIONS_SUCCESS:
            return { 
                questionsLoading:false,
                questions: action.items,
                error:""
            };

        case genericConstants.QUESTIONS_FAILURE:
            return {
                questionsLoading:false,
                questions: [], 
                error:action.error
            }; 

        default:
            return state
    }
}