/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Form, Label, Input, Button } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { answersActions } from '../../actions';
import { answersService } from '../../services';

class AnswersEdit extends Component {
    constructor(props) {
        super(props);
        var pid = props.match.params.pid;
        this.state = {
            alert: null,
            show: false,
            id: pid,
            data: [],
            isAdd: false,
            validation: {
                text: 'has-danger',
                value: 'has-danger',
            },
            text: '',
            value: ''
        };

        if (props.location.state && props.location.state.theAnswer) {
            var p = props.location.state.theAnswer;
            if (p && p !== null) {
                this.state.text = p.text;
                this.state.value = p.value;
                this.state.validation = {
                    text: '',
                    value: ''
                }
            } else {
                this.props.history.goBack();
            }
        } else {
            this.state.isAdd = true;
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        switch (name) {
            case 'text':
                if (value.length >= 10) {
                    this.state.validation["text"] = "has-success";
                } else {
                    this.state.validation["text"] = "has-danger";
                }
                break;
            case 'value':
                if (value >= 0 && value <= 1000) {
                    this.state.validation["value"] = "has-success";
                } else {
                    this.state.validation["value"] = "has-danger";
                }
                break;

            default:
                break;
        }
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    hideAlert1() {
        this.setState({ alert: null });
        this.props.history.goBack();
    }

    saveAnswer() {
        if (this.state.validation.text === "has-danger" ||
            this.state.validation.answers === "has-danger" ) {
            this.setState({
                alert: (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "100px" }}
                        title="Something when wrong!"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                    >
                        Please review the errors and try again.
                  </SweetAlert>
                )
            });
        } else {
            var theAnswer = {};

            if (this.state.isAdd !== true) {
                theAnswer = this.props.location.state.theAnswer;
            } else {
                //theAnswer.branchType = "NO_BRANCH";
            }
            theAnswer.text = this.state.text;
            theAnswer.value = this.state.value;
        }

        if (this.state.isAdd !== true) {
            answersService.saveAnswer(theAnswer, theAnswer.id).then(r => {
                this.setState({
                    alert: (
                        <SweetAlert
                            success
                            style={{ display: "block", marginTop: "100px" }}
                            title="Answer Saved"
                            onConfirm={() => this.hideAlert1()}
                            onCancel={() => this.hideAlert1()}
                            confirmBtnBsStyle="info"
                        >
                        </SweetAlert>
                    )
                });

            }
            );
        } else {
            answersService.addAnswer(theAnswer).then(r => {
                this.setState({
                    alert: (
                        <SweetAlert
                            success
                            style={{ display: "block", marginTop: "100px" }}
                            title="Answer Saved"
                            onConfirm={() => this.hideAlert1()}
                            onCancel={() => this.hideAlert1()}
                            confirmBtnBsStyle="info"
                        >
                        </SweetAlert>
                    )
                });
            }
            );
        }
    }

    render() {
        //const { items, answersLoading } = this.props;
        var title = 'Create new answer';
        if (this.state.id > 0) {
            title = `Edit answer ${this.state.id}`
        }



        const textValidationError = () => {
            if (this.state.validation.text === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Answer has to be more than 2 characters in length.
                        </small>
                        </p>
                    </>);
            }
        }

        const answersValidationError = () => {
            if (this.state.validation.value === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Answer needs a value between 0 and 1000.
                        </small>
                        </p>
                    </>);
            }
        }


        return (

            <>
                {this.state.alert}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Card>
                                        <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                        <Button className="btn-fill pull-right" color="success" type="submit"
                                            onClick={this.saveAnswer.bind(this)}>
                                            Submit
                                        </Button>
                                        {"  "}
                                        <Button className="btn-fill pull-right" color="danger" type="submit"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}>
                                            Cancel
                                        </Button>
                                    </Card>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={7}>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <CardTitle tag="h5">Answer</CardTitle>
                                                        {textValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.text}>
                                                            <Input type="text" name="text"
                                                                value={this.state.text}
                                                                onChange={this.handleChange.bind(this)} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <CardTitle tag="h5">Range Answers</CardTitle>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <label>Value</label>
                                                        {valueValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.value}>
                                                            <Input type="number" name="value"
                                                                value={this.state.value}
                                                                onChange={this.handleChange.bind(this)} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>

                                <CardFooter className="pull-right">
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { items, answersLoading } = state.answers;
    return { items, answersLoading };
}

const actionCreators = {
    getAll: answersActions.getAll
};

const connectedAnswersEdit = connect(mapState, actionCreators)(AnswersEdit);
export { connectedAnswersEdit as AnswersEdit }; 