import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { participants } from './participants.reducer';
import { providers } from './providers.reducer';
import { alarms } from './alarms.reducer';
import { questions } from './questions.reducer';
import { forms } from './forms.reducer';
import { answers } from './answers.reducer';
import { screens } from './screens.reducer';
import { settings } from './settings.reducer';
import { lookups } from './lookups.reducer';
import { overviewstats } from './overviewstats.reducer';
import { wallpapers } from './wallpapers.reducer';
import { concepts } from './concepts.reducer';

const rootReducer = combineReducers({
  authentication,
  participants,
  providers,
  alarms,
  concepts,
  questions,
  forms,
  answers,
  screens,
  settings,
  lookups,
  overviewstats,
  wallpapers
});

export default rootReducer;