
import { genericConstants } from '../constants';
 
const initialState = {   
    wallpapersLoading:false, 
    error:""
} ;

export function wallpapers(state = initialState, action) {
    switch (action.type) {
        case genericConstants.WALLPAPERS_REQUEST:
            return {
                wallpapersLoading: true
            };

        case genericConstants.WALLPAPERS_SUCCESS:
            return { 
                wallpapersLoading:false,
                wallpapers: action.items,
                error:""
            };

        case genericConstants.WALLPAPERS_FAILURE:
            return {
                wallpapersLoading:false,
                wallpapers: [], 
                error:action.error
            }; 

        default:
            return state
    }
}