import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';

import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardHeader, CardBody, Row,
  Col, CardTitle, Button, CardFooter, FormGroup, Label, Input
} from "reactstrap";

import Datetime from "react-datetime";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { Line } from "react-chartjs-2";
import { participantsActions } from '../../actions';
import { questionsActions } from '../../actions';
import { dataTransService } from '../../services';



class ParticipantSummary extends Component {
  constructor(props) {
    super(props);
    var emptyChartData = {
      data: canvas => {
        return {
          labels: [],
          datasets: []
        }
      }
    };

    this.state = {
      pId: ' - loading data ... - ',
      hTabs: "ht1",
      conceptsStartDate: Datetime.moment(),
      conceptsEndDate: Datetime.moment(),
      questionsStartDate: Datetime.moment(),
      questionsEndDate: Datetime.moment(),

      conceptsMinDate: Datetime.moment(),
      conceptsMaxDate: Datetime.moment(),
      questionsMinDate: Datetime.moment(),
      questionsMaxDate: Datetime.moment(),

      minmaxDateFixed: false,

      concepts: [],
      questions: [],
      //questionsArray: [],
      conceptsChartData: {
        data: emptyChartData
      },

      selectAllQ: 'Unselect All',
      selectAllC: 'Unselect All',

      selectAllQE: true,
      selectAllCE: true,

      questionsChartData: {
        data: emptyChartData
      },
    };

    this.props.getSummary(props.match.params.pid);
    //this.state.questionsArray = this.props.getQuestions(); //JLIZASO
    //console.log("JLIZASO this.state.questionsArray:", this.state.questionsArray);
  
    this.MinMaxAnswerValues = { //JLIZASO
      max:10,
      min:0
    }
  
    this.setChartOptions();

  }

  setChartOptions() {
    this.chartOptions = {
      animations: {
        radius: {
          duration: 400,
          easing: 'linear',
          loop: (context) => context.active
        }
      },
      spanGaps: true,
      layout: {
        padding: { left: 15, right: 15, top: 5, bottom: 100 }
      },
      maintainAspectRatio: false,
      responsive: 1,
      tooltips: {
        bodySpacing: 4,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10
      },
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
              zeroLineColor: "transparent",
              drawTicks: true,
              drawBorder: true,
            },
            ticks: {
              fontStyle: "bold",
              beginAtZero: false,
              padding: 5,
              suggestedMin: this.MinMaxAnswerValues.min, // Multitouch: The range is now variable. //JLIZASO
              suggestedMax: this.MinMaxAnswerValues.max, // DAWNP: The range of values is from 0 to 3
              min: this.MinMaxAnswerValues.min, // DAWNP: The range of values is from 0 to 3
              max: this.MinMaxAnswerValues.max, // DAWNP: The range of values is from 0 to 3
              stepSize: 1
            }
          }
        ],
        xAxes: [{
          type: 'time',
          distribution: 'linear',
          ticks: {
            source: 'labels',
            padding: 10,
            beginAtZero: false,
            fontStyle: "bold",
            //maxTicksLimit: 8.1,
            autoSkip: false,
            major: {
              enabled: true
            },
            callback: function(value, index, values) {
              return ' ' + value;
            }
          },
          time: {
            displayFormats: {
              day: 'DD/MM'
            },
            tooltipFormat: 'DD/MM/YY HH:MM'
          },
          gridLines: {
            display: true,
            zeroLineColor: "transparent",
            drawTicks: true,
            drawBorder: false,
          },
        }]
      }
    };
  }

  handleChangeStartSymptoms(date) {
    this.setState({ conceptsStartDate: date });
    this.plotContepts(undefined, date, undefined);
  }

  handleChangeEndSymptoms(date) {
    this.setState({ conceptsEndDate: date });
    this.plotContepts(undefined, undefined, date);
  }

  handleChangeStartQuestions(date) {
    this.setState({ questionsStartDate: date });
    this.plotQuestions(undefined, date, undefined);
  }

  handleChangeEndQuestions(date) {
    this.setState({ questionsEndDate: date });
    this.plotQuestions(undefined, undefined, date);
  }

  plotQuestions(questionsArr, dstart, dend) {

    if (dstart === undefined) {
      dstart = this.state.questionsStartDate;
    }

    if (dend === undefined) {
      dend = this.state.questionsEndDate;
    }

    if (questionsArr === undefined) {
      questionsArr = this.state.questions;
    }

    var selectedQuestions = [];

    for (let i = 0; i < questionsArr.length; i++) {
      const q = questionsArr[i];
      if (q.selected === true) {
        selectedQuestions.push(q);
      }
    }

    const datasets = dataTransService.getQuestionsPlotData(
      this.props.summary.encounters,
      selectedQuestions,
      {
        minDate: Datetime.moment(dstart),
        maxDate: Datetime.moment(dend),
      }
    );



    const labels = dataTransService.getXAxisTicks(
      Datetime.moment(dstart).valueOf(),
      Datetime.moment(dend).valueOf()
    ).map(date => date[0]);

    var newQuestionsChartData = {
      data: canvas => {
        return {
          labels: labels,
          datasets: datasets
        }
      }
    }
    this.setState({ questionsChartData: newQuestionsChartData });
  }

  plotContepts(conceptsArr, dstart, dend) {
    if (dstart === undefined) {
      dstart = this.state.conceptsStartDate;
    }

    if (dend === undefined) {
      dend = this.state.conceptsEndDate;
    }

    if (conceptsArr === undefined) {
      conceptsArr = this.state.concepts;
    }

    var selectedConcepts = [];

    for (let i = 0; i < conceptsArr.length; i++) {
      const q = conceptsArr[i];
      if (q.selected === true) {
        selectedConcepts.push(q);
      }
    }

    var datasets = dataTransService.getConceptsPlotData(
      this.props.summary.encounters,
      selectedConcepts,
      {
        minDate: Datetime.moment(dstart),
        maxDate: Datetime.moment(dend),
      }
    );

    const labels = dataTransService.getXAxisTicks(
      Datetime.moment(dstart).valueOf(),
      Datetime.moment(dend).valueOf()
    ).map(date => date[0]);

    var newConceptsChartData = {
      data: canvas => {
        return {
          labels: labels,
          datasets: datasets
        }
      }
    }
    this.setState({ conceptsChartData: newConceptsChartData });
  }


  changeQuestionsSelection() {
    var selection = !this.state.selectAllQE;
    if (selection === false) {
      this.setState({ selectAllQ: 'Select All' });
    } else {
      this.setState({ selectAllQ: 'Unselect All' });
    }
    this.setState({ selectAllQE: selection });

    var newQuestions = this.state.questions.map(el => {
      el.selected = selection;
      return el;
    });
    this.setState({ questions: newQuestions })
    this.plotQuestions(newQuestions);
  }

  changeConceptsSelection() {
    var selection = !this.state.selectAllCE;
    if (selection === false) {
      this.setState({ selectAllC: 'Select All' });
    } else {
      this.setState({ selectAllC: 'Unselect All' });
    }
    this.setState({ selectAllCE: selection });

    var newConcepts = this.state.concepts.map(el => {
      el.selected = selection;
      return el;
    });
    this.setState({ concepts: newConcepts })
    this.plotContepts(newConcepts);
  }

  toggleModalNotice = (el) => {

    el.modalNotice = !el.modalNotice;
    this.forceUpdate();
  };

  isValidStartDateForConcepts(current) {
    return current.isBefore(this.state.conceptsEndDate);
  }

  isValidEndDateForConcepts(current) {
    return current.isAfter(this.state.conceptsStartDate);
  }

  isValidStartDateForQuestions(current) {
    return current.isBefore(this.state.questionsEndDate);
  }

  isValidEndDateForQuestions(current) {
    return current.isAfter(this.state.questionsStartDate);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props && this.props.summary && this.props.summary.encounters &&
      this.props.summary.encounters.length > 0 &&
      (!(prevProps.summary && prevProps.summary.timestamp) ||
        this.props.summary.timestamp > prevProps.summary.timestamp)) {

      var dateRange = dataTransService.getEncountersDatesRange(this.props.summary.encounters);

      this.setState({
        conceptsStartDate: dateRange.minDate,
        conceptsEndDate: dateRange.maxDate,
        questionsStartDate: dateRange.minDate,
        questionsEndDate: dateRange.maxDate
      }, () => {
        if (!this.state.minmaxDateFixed) {
          this.setState({
            conceptsMinDate: dateRange.minDate,
            conceptsMaxDate: dateRange.maxDate,
            questionsMinDate: dateRange.minDate,
            questionsMaxDate: dateRange.maxDate,
            minmaxDateFixed: true
          })
        }
      });

      var conceptsArr = dataTransService.getAllConcepts(this.props.summary.encounters,this.props.summary.participant.alertConfig.conceptWeights);
      var questionsArr = dataTransService.getAllQuestions(this.props.summary.encounters,this.props.summary.participant.alertConfig.conceptWeights);

      this.setState({ concepts: conceptsArr });
      this.setState({ questions: questionsArr });

      if (dateRange.minDate !== this.state.questionsStartDate ||
        dateRange.maxDate !== this.state.questionsEndDate) {
        this.plotQuestions(questionsArr, dateRange.minDate, dateRange.maxDate);
      }

      if (dateRange.minDate !== this.state.conceptsStartDate ||
        dateRange.maxDate !== this.state.conceptsEndDate) {
        this.plotContepts(conceptsArr, dateRange.minDate, dateRange.maxDate);
      }
    }
  }

  handleCheckConcept = (event) => {
    let concepts = this.state.concepts
    concepts.forEach(concept => {
      if (concept.id === event.target.value)
        concept.selected = event.target.checked
    })
    this.setState({ concepts: concepts })
    this.plotContepts(concepts);
  }

  handleCheckQuestions = (event) => {
    let questions = this.state.questions
    questions.forEach(question => {
      if (question.uuid === event.target.value)
        question.selected = event.target.checked
    })
    this.setState({ questions: questions })
    this.plotQuestions(questions);
  }

  render() {
    const { summary, participantsLoading } = this.props;

    console.log("this.state: ", this.state)

    if(this.state.questions!==undefined){
      this.MinMaxAnswerValues = dataTransService.getMaxMinAnswerValues(this.state.questions);
      this.setChartOptions();
    }
    console.log("this.MinMaxAnswerValues: ", this.MinMaxAnswerValues)
  
    if (summary && summary.encounters && summary.encounters.length > 0) {
      var participant = summary.participant.user.login;
      var storedEncounters = [];
      var encounters = summary.encounters.map(el => {
        storedEncounters.push({key: el.id, data: el.details, dayNumber: el.dayNumber, eventNumber: el.eventNumber, participant: participant});
        return {
          id: el.id,
          edate: Datetime.moment.utc(el.datetime).format('DD-MM-YYYY'),
          etime: Datetime.moment.utc(el.datetime).format('HH:mm'),
          etype: el.status,
          eday: el.dayNumber,
          eevent: el.eventNumber,
          actions: (
            <div className="actions-right">
              <Button
                onClick={() => this.props.history.push(`/admin/participants/${this.props.match.params.pid}/encounters/${el.id}`)}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fa fa-info" />
              </Button>
            </div>
          )
        };
      });
      window.localStorage.setItem('participantEncounters', JSON.stringify(storedEncounters));
    }


    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row className="justify-content-center">
            <Col lg={12} md={12} xs={12}>
              <Card>
                <CardHeader>
                  <h3>
                    Participant Summary for {participant}
                  </h3>
                </CardHeader>
                <CardBody>
                  <Nav pills className="nav-pills-primary">
                    <NavItem>
                      <NavLink className={this.state.hTabs === "ht1" ? "active" : ""}
                        onClick={() => this.setState({ hTabs: "ht1" })}                      >
                        Encounters
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={this.state.hTabs === "ht2" ? "active" : ""}
                        onClick={() => this.setState({ hTabs: "ht2" })} >
                        Symptom Graphs
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={this.state.hTabs === "ht3" ? "active" : ""}
                        onClick={() => this.setState({ hTabs: "ht3" })}>
                        Question Graphs
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={this.state.hTabs}
                    className="tab-space">
                    <TabPane tabId="ht1">
                      <ReactTable
                        data={encounters}
                        columns={[
                          {
                            Header: "Encounter date",
                            accessor: "edate"
                          },
                          {
                            Header: "Time",
                            accessor: "etime"
                          },
                          {
                            Header: "Encounter type",
                            accessor: "etype"
                          },
                          {
                            Header: "Day",
                            accessor: "eday"
                          },
                          {
                            Header: "Event",
                            accessor: "eevent"
                          },
                          {
                            Header: "Actions",
                            accessor: "actions"
                          }
                        ]}
                        pageSize={encounters ? encounters.length : 5}
                        showPagination={false}
                        className="-striped"
                        loading={participantsLoading}
                      />
                    </TabPane>
                    <TabPane tabId="ht2">
                      <Row>
                        <Col xs={12} md={12} className="mr-auto">
                          <Card className="card-chart">
                            <CardHeader>
                              <CardTitle tag="h5">Time Series Graph of Symptoms</CardTitle>
                              <Row>
                                <Col>
                                  <label>Start Date</label>
                                  <Datetime
                                    value={this.state.conceptsStartDate}
                                    onChange={this.handleChangeStartSymptoms.bind(this)}
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat={false}
                                    isValidDate={this.isValidStartDateForConcepts.bind(this)}
                                    inputProps={{ placeholder: "Start Date" }} />
                                </Col>
                                <Col>
                                  <label>End  Date</label>
                                  <Datetime
                                    value={this.state.conceptsEndDate}
                                    onChange={this.handleChangeEndSymptoms.bind(this)}
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat={false}
                                    isValidDate={this.isValidEndDateForConcepts.bind(this)}
                                    inputProps={{ placeholder: "End Date" }} />
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col xs={12} md={8}>
                                  <div className="chart-area">
                                    <Line data={this.state.conceptsChartData.data} options={this.chartOptions} />
                                  </div>
                                </Col>
                                <Col xs={12} md={4}>
                                  <Button className="btn-fill" color="info"
                                    onClick={this.changeConceptsSelection.bind(this)}>
                                    {this.state.selectAllC}
                                  </Button>
                                  {
                                    this.state.concepts.map((el, i) => {
                                      return (<FormGroup check key={`c${i}`}>
                                        <Label check   >
                                          <Input type="checkbox" checked={el.selected} onChange={this.handleCheckConcept.bind(this)} value={el.id} />
                                          <span className="form-check-sign" />
                                          <div style={{ backgroundColor: el.color, width: 10, height: 10, display: 'inline-block' }}></div>  {el.name}
                                        </Label>
                                      </FormGroup>);
                                    })
                                  }
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="ht3">
                      <Row>
                        <Col xs={12} md={12} className="mr-auto">
                          <Card className="card-chart">
                            <CardHeader>
                              <CardTitle tag="h5">Time Series Graph of Questions</CardTitle>
                              <Row>
                                <Col>
                                  <label>Start Date</label>
                                  <Datetime
                                    value={this.state.questionsStartDate}
                                    onChange={this.handleChangeStartQuestions.bind(this)}
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat={false}
                                    isValidDate={this.isValidStartDateForQuestions.bind(this)}
                                    inputProps={{ placeholder: "Start Date" }} />
                                </Col>
                                <Col>
                                  <label>End  Date</label>
                                  <Datetime
                                    value={this.state.questionsEndDate}
                                    onChange={this.handleChangeEndQuestions.bind(this)}
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat={false}
                                    isValidDate={this.isValidEndDateForQuestions.bind(this)}
                                    inputProps={{ placeholder: "End Date" }} />
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody >


                              <Row>
                                <Col xs={12} md={8}>
                                  <div className="chart-area">
                                    <Line data={this.state.questionsChartData.data} options={this.chartOptions} />
                                  </div>
                                </Col>
                                <Col xs={12} md={4}>
                                  <Button className="btn-fill" color="info"
                                    onClick={this.changeQuestionsSelection.bind(this)}>
                                    {this.state.selectAllQ}
                                  </Button>
                                  {
                                    this.state.questions.map((el, i) => {
                                      return (<FormGroup check key={`q${i}`}>
                                        <Label check   >
                                          <Input type="checkbox" checked={el.selected} onChange={this.handleCheckQuestions.bind(this)} value={el.uuid} />
                                          <span className="form-check-sign" />
                                          <div style={{ backgroundColor: el.color, width: 10, height: 10, display: 'inline-block' }}></div>  {el.text}
                                        </Label>
                                      </FormGroup>);
                                    })
                                  }
                                </Col>
                              </Row>


                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
                <CardFooter className="pull-right">
                  <Button className="btn-fill" color="danger" type="submit"
                    onClick={() => {
                      this.props.history.push('/admin/participants');
                    }}>
                    Go Back
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>

        </div>
      </>
    );
  }
}


function mapState(state) {
  const { summary, questions, participantsLoading } = state.participants;

  return { summary, questions, participantsLoading };
}

const actionCreators = {
  getSummary: participantsActions.getSummary,
  getQuestions: questionsActions.getAll
};

const connectedParticipantSummary = connect(mapState, actionCreators)(ParticipantSummary);
export { connectedParticipantSummary as ParticipantSummary }; 