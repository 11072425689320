  
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export function questionsListSelector(pformId) {
    var formId = {"value":"00bf350c-126a-4b3e-a17a-951c44cbdd2b", "label":"CORE - Careloop"};
    if (pformId === undefined) {
        pformId = JSON.parse(localStorage.getItem('questionsListSelector'));
        //console.log("questionsListSelector localStorage: ", formId);
        if(pformId===null){
            window.localStorage.setItem("questionsListSelector",JSON.stringify(formId));
        } else {
            formId = pformId;
        }
    } else {
        formId = pformId;
        window.localStorage.setItem("questionsListSelector",JSON.stringify(formId));
    }

    //console.log("questionsListSelector formId: ", formId);
    return formId;
}