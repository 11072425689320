/*eslint-disable*/
import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { screensActions } from '../../actions';

class Screens extends Component {
  constructor(props) {
    super(props);
    this.props.getAll();
    this.state = {
      data: []
    };

    var type = [
      { value: "TERMS", label: "Terms & Conditions" },
      { value: "HELP", label: "Help Screen" },
      { value: "Phone", label: "Helpful phone numbers" }
    ];
  }

  render() {
    const { screens } = this.props;

    if (screens && screens.length > 0) {
      var dataItems = screens.map((el) => {
        return {
          id: el.id,
          type: el.type,
          title: el.title,
          enabled: el.enabled === true? "Yes" : "No",
          actions: (
            <div className="actions-right">
              <Button
                onClick={() => {
                  this.props.history.push({
                    pathname: `/admin/screens/${el.id}/edit`,
                    state: { theScreen: el }
                  });
                }}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fa fa-edit" />
              </Button>
            </div>
          )
        };
      });
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Screens</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={dataItems}
                    filterable
                    columns={[
                      {
                        Header: "Screen",
                        accessor: "title"
                      },
                      {
                        Header: "Enabled",
                        accessor: "enabled"
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { screens } = state.screens;
  return { screens };
}

const actionCreators = {
  getAll: screensActions.getAll
};

const connectedScreens = connect(mapState, actionCreators)(Screens);
export { connectedScreens as Screens }; 