/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Form } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        const styles = {
            padding: {
                paddingLeft: "5%",
                paddingRight: "5%"
            }
        };

        return (

            <>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Privacy Notice – Clinician Dashboard</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Row style={styles.padding}>
                                                    <Col xs={12} md={12}>
                                                        <p>This is the clinician dashboard privacy notice of Affigo CIC (“Affigo CIC”, “we”, “us”, “our”) 
                                                            <br/>We are company number 09928775 registered in the United Kingdom.
                                                            <br/>Our registered office is at Affigo CIC, Suite 308, 275 Deansgate, Manchester, M3 4EL, England
                                                            <br/>
                                                            <br/>Effective date: 
                                                            <br/>Last reviewed: 12/01/2021
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">Introduction</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>Affigo CIC (“Affigo CIC”, “we”, “us”, “our”) designs and develops applications (“Apps”) to empower users to manage their health condition and improve their health outcomes. This privacy notice aims to inform you about how we collect and process any information that we collect from you, or that you provide to us. It covers information that could identify you (“personal information”) and information that could not. In the context of the law and this notice, “process” means collect, store, transfer, use or otherwise act on information. It tells you about your privacy rights and how the law protects you.
                                                        <br/>We are committed to protecting your privacy and the confidentiality of your personal information. Our policy is not just an exercise in complying with the law, but a continuation of our respect for you and your personal information.
                                                        <br/>We undertake to preserve the confidentiality of all information you provide to us, and hope that you reciprocate.
                                                        <br/>Our policy complies with the Data Protection Act 2018 (Act) accordingly incorporating the EU General Data Protection Regulation (GDPR).
                                                        <br/>The law requires us to tell you about your rights and our obligations to you in regard to the processing and control of your personal data. We do this now, by requesting that you read the information provided at http://www.knowyourprivacyrights.org
                                                        <br/>Except as set out below, we do not share, or sell, or disclose to a third party, any information collected through our website.
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">1. Data Protection Officer</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>We have appointed a data protection officer (DPO) who is responsible for ensuring that our policy is followed.
                                                        <br/>If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact our DPO at <a href="support@affigo.io">dpo@affigo.io</a>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">2. Data we process</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>We may collect, use, store and transfer different kinds of personal data about you. We have collated these into groups as follows:
                                                        <br/>Your identity includes information such as first name, last name, title, and other identifiers that you may have provided at some time.
                                                        <br/>Your contact information includes information such as email address, telephone numbers and any other information you have given to us for the purpose of communication or meeting.
                                                        <br/>Technical data includes your internet protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.
                                                        <br/>We may aggregate anonymous data such as statistical or demographic data for any purpose. Anonymous data is data that does not identify you as an individual. Aggregated data may be derived from your personal data but is not considered personal information in law because it does not reveal your identity.
                                                        <br/>For example, we may aggregate profile data to assess interest in a product or service.
                                                        <br/>However, if we combine or connect aggregated data with your personal information so that it can identify you in any way, we treat the combined data as personal information and it will be used in accordance with this privacy notice.
                                                        </p>
                                                        <CardTitle tag="h6">a) Special category personal information</CardTitle>
                                                        <p>Special category personal information is data about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data.
                                                        <br/>We do not collect any special category personal information about you.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">3. If you do not provide personal information we need </CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform that contract. In that case, we may have to stop providing a service to you. If so, we will notify you of this at the time.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">4. The bases on which we process information about you </CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>The law requires us to determine under which of six defined bases we process different categories of your personal information, and to notify you of the basis for each category.
                                                        <br/>If a basis on which we process your personal information is no longer relevant then we shall immediately stop processing your data.
                                                        <br/>If the basis changes then if required by law we shall notify you of the change and of any new basis under which we have determined that we can continue to process your information.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">5. Information we process because we have a contractual obligation with you</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>When you have an account on our website, or one created or you by a member of your clinical team, or otherwise agree to our terms and conditions, a contract is formed between you and us.
                                                        <br/>In order to carry out our obligations under that contract we must process the information you give us. Some of this information may be personal information.</p>
                                                        <p>We may use it in order to:</p>
                                                        <ul>
                                                            <li>verify your identity for security purposes</li>
                                                            <li>provide you with our services</li>
                                                        </ul>
                                                        <p>We process this information on the basis there is a contract between us, or that you have requested we use the information before we enter into a legal contract.
                                                        <br/>We shall continue to process this information until the contract between us ends or is terminated by either party under the terms of the contract.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">6. Information we process with your consent</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our website or ask us to provide you more information about our business, including our products and services, you provide your consent to us to process information that may be personal information.
                                                        <br/>Wherever possible, we aim to obtain your explicit consent to process this information, for example, by asking you to agree to our use of cookies.
                                                        <br/>We continue to process your information on this basis until you withdraw your consent or it can be reasonably assumed that your consent no longer exists.
                                                        <br/>You may withdraw your consent at any time by instructing us at dpo@affigo.io However, if you do so, you may not be able to use our website or our services further.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">7. Information we process for the purposes of legitimate interests</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>We may process information on the basis there is a legitimate interest, either to you or to us, of doing so.</p>
                                                        <p>Where we process your information on this basis, we do after having given careful consideration to:</p>
                                                        <ul>
                                                            <li>whether the same objective could be achieved through other means</li>
                                                            <li>whether processing (or not processing) might cause you harm</li>
                                                            <li>whether you would expect us to process your data, and whether you would, in the round, consider it reasonable to do so</li>
                                                        </ul>
                                                        <br/>
                                                        <p>For example, we may process your data on this basis for the purposes of:</p>
                                                        <ul>
                                                            <li>record-keeping for the proper and necessary administration of our business</li>
                                                            <li>responding to unsolicited communication from you to which we believe you would expect a response</li>
                                                            <li>protecting and asserting the legal rights of any party</li>
                                                            <li>insuring against or obtaining professional advice that is required to manage business risk</li>
                                                            <li>protecting your interests where we believe we have a duty to do so</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">8. Information we process because we have a legal obligation</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>Sometimes, we must process your information in order to comply with a statutory obligation.
                                                        <br/>For example, we may be required to give information to legal authorities if they so request or if they have the proper authorisation such as a search warrant or court order.
                                                        <br/>This may include your personal information.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">9. Third parties</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>We use third parties to process some of your personal data on our behalf. When we allow them access to your data, we do not permit them to use it for their own purposes. We have in place with each processor, a contract that requires them only to process the data on our instructions and to take proper care in using it. They are not permitted to keep the data after our relationship with them has ended.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">10.	Complaints regarding content on our website</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>If you complain about any of the content on our website, we shall investigate your complaint.
                                                        <br/>If we feel it is justified or if we believe the law requires us to do so, we shall remove the content while we investigate.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">11.	Communicating with us</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>When you contact us, whether by telephone, through our website or by e-mail, we collect the data you have given to us in order to reply with the information you need.
                                                        <br/>We record your request and our reply in order to increase the efficiency of our business. We keep personally identifiable information associated with your message, such as your name and email address so as to be able to track our communications with you to provide a high quality service.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">12. Complaining</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>When we receive a complaint, we record all the information you have given to us.
                                                        <br/>We use that information to resolve your complaint. If your complaint reasonably requires us to contact some other person, we may decide to give to that other person some of the information contained in your complaint. We do this as infrequently as possible, but it is a matter for our sole discretion as to whether we do give information, and if we do, what that information is.
                                                        <br/>We may also compile statistics showing information obtained from this source to assess the level of service we provide, but not in a way that could identify you or any other person.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">13. Cookies</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>When you interact with our website we try to make that experience simple and meaningful. When you visit the website, our web server sends a cookie to the hard disk your computer. Cookies are small text files which are issued to your computer when you visit a website and which store and sometimes track information about your use of the site. For example, cookies are used to personalise web search engines and to store shopping lists of items a user has selected while browsing through a virtual shopping mall. Some of the cookies we use last only for the duration of your web session and expire when you close your browser. Other cookies are used to remember you when you return to the site and will last for longer.
                                                        <br/> 
                                                        <br/>We use cookies to:</p>
                                                        <ul>
                                                            <li>Remember that you have visited us before; this means we can identify the number of unique visitors we receive. This allows us to make sure we have enough capacity for the number of users that we get.</li>
                                                            <li>Collect statistical information about how you use the site (including how long you spend there) and where you have come to the site from. We collect this data so that we can improve the website and learn which parts are most popular with visitors.</li>
                                                            <li>Improve speed site navigation and recognise your access rights on the site.</li>
                                                        </ul>
                                                        <p>Your web browser should allow you to delete any you choose. It also should allow you to prevent or limit their use.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">Disclosure and sharing of your information</CardTitle>
                                                    <br/>
                                                    <CardTitle tag="h5">14.	Data may be processed outside the European Union</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>Our websites are hosted in the United Kingdom. We may also use outsourced services in countries outside the European Union from time to time in other aspects of our business.
                                                        <br/>Accordingly data obtained within the UK or any other country could be processed outside the European Union.
                                                        <br/>For example, some of the software our website uses may have been developed in the South America.</p>
                                                        <p>We use the following safeguards with respect to data transferred outside the European Union:</p>
                                                        <ul>
                                                            <li>The data protection clauses in our contracts with data processors include transfer clauses written by or approved by a supervisory authority in the European Union, specifically that in the United Kingdom.</li>
                                                            <li>We comply with a code of conduct approved by a supervisory authority in the European Union, specifically that in the United Kingdom.</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">Control over your own information</CardTitle>
                                                    <br/>
                                                    <CardTitle tag="h5">15.	Your duty to inform us of changes</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">16.	Access to your personal information</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>To obtain a copy of any information that is not provided on our website you should contact us to make that request.
                                                        <br/>After receiving the request, we will tell you when we expect to provide you with the information, and whether we require any fee for providing it to you.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">17.	Removal of your information</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>If you wish us to remove personally identifiable information, you should contact us at dpo@affigo.io to make your request. This may limit the service we can provide to you.
                                                        <br/>When we receive any request to access, edit or delete personal identifiable information we shall first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">Other matters</CardTitle>
                                                    <br/>
                                                    <CardTitle tag="h5">18.	Use of site by children</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>We do not sell products or provide services for purchase by children, nor do we market to children.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">19.	Encryption of data sent between us</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>We use Secure Sockets Layer (SSL) certificates to verify our identity to your browser and to encrypt any data you give us.
                                                        <br/>Whenever information is transferred between us, you can check that it is done so using SSL by looking for a closed padlock symbol or other trust mark in your browser’s URL bar or toolbar.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">20.	How you can complain</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>If you are not happy with our privacy policy or if you have any complaint then you should tell us by contacting <a href="support@affigo.io">dpo@affigo.io</a>
                                                        <br/>If a dispute is not settled then we hope you will agree to attempt to resolve it by engaging in good faith with us in a process of mediation or arbitration. 
                                                        <br/>If you are in any way dissatisfied about how we process your personal information, you have a right to lodge a complaint with the Information Commissioner's Office (ICO). This can be done at <a href="https://ico.org.uk/make-a-complaint/" target="_blank">https://ico.org.uk/make-a-complaint/</a>. We would, however, appreciate the opportunity to talk to you about your concern before you approach the ICO.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">21.	Retention period for personal data</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>Except as otherwise mentioned in this privacy notice, we keep your personal information only for as long as required by us:</p>
                                                        <ul>
                                                            <li>to provide you with the services you have requested;</li>
                                                            <li>to comply with other law, including for the period demanded by our tax authorities;</li>
                                                            <li>to support a claim or defence in court.</li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">22.	Compliance with the law</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>Our privacy policy has been compiled so as to comply with the law of every country or legal jurisdiction in which we aim to do business. If you think it fails to satisfy the law of your jurisdiction, we should like to hear from you.
                                                        <br/>However, ultimately it is your choice as to whether you wish to use our website.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">23.	Review of this privacy policy</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p>We may update this privacy notice from time to time as necessary. The terms that apply to you are those posted here on our website on the day you use our website. We advise you to print a copy for your records.
                                                        <br/>If you have any question regarding our privacy policy, please contact us at <a href="support@affigo.io">dpo@affigo.io</a></p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


function mapState(state) {
}

const connectedPrivacyPolicy = connect(mapState)(PrivacyPolicy);
export { connectedPrivacyPolicy as PrivacyPolicy }; 