import { genericConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from '.';
import { history } from '../helpers';
import decode from 'jwt-decode';

export const authActions = {
    login,
    logout
};

function login(username, password) {
     
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                userToken => { 
                    var fToken = decode(userToken);
                    // console.log(fToken.sub);
                    userService.getCurrentUser(fToken.sub,userToken).then( 
                        cUser=>{
                            window.localStorage.setItem("login",cUser.login);
                            window.localStorage.setItem("role",cUser.role);
                            window.localStorage.setItem("id",cUser.id);
                            
                            dispatch(success(userToken));
                            history.push('/');
                        },
                        error => {
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                        }
                    );
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(err => {
                console.log(err);
                alertActions.error("Invalid User/Password");
            });
    };

    function request(username) { return { type: genericConstants.LOGIN_REQUEST, username } }
    function success(userToken) { return { type: genericConstants.LOGIN_SUCCESS, userToken } }
    function failure(error) { return { type: genericConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: genericConstants.LOGOUT };
}