
import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";

import nowLogo from "assets/img/clintouch-logo_0_0.png";
import bgImage from "assets/img/bg13.jpg";

import { authActions } from '../../actions';
import { Redirect } from "react-router-dom";

class LoginPage extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      usernameFocus: false,
      passwordFocus: false,
      errorLogin: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ errorLogin: '' });
  }

  componentDidMount() {
    document.body.classList.add("login-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("login-page");
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    var loginResponse = '';
    
    if (username && password) {
      loginResponse = this.props.login(username, password);
    } else {
      this.setState({ errorLogin: 'missing-credentials' });
    }

    console.log("loginResponse: ", loginResponse)
  }

  render() {
    const { loggedIn } = this.props;
    const { username, password } = this.state;

    if(loggedIn === true ){
      return <Redirect to='/admin/participants'/>
    }

    const badCredentialsError = () => {
      var errorLoginTxt = '';

      if (this.state.errorLogin === "bad-credentials") {
        errorLoginTxt = 'Invalid Username/Password';
      }

      if (this.state.errorLogin === "missing-credentials") {
        errorLoginTxt = 'Please enter a valid Username/Password';
      }

      console.log("Error Login: ", this.state.errorLogin, errorLoginTxt);

      if (this.state.errorLogin !== '') {
          return (
              <>
                  <p className="login-error">
                      {errorLoginTxt}
                  </p>
              </>);
      }
  }

    return (
      <>
        <div className="content">
          <div className="login-page">
            <Container>
              <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                <Form onSubmit={this.handleSubmit}>
                  <Card className="card-login card-plain">
                    <CardHeader>
                      <div className="logo-container" style={{width:140 ,height:140, marginBottom:0}}>
                        <img src={nowLogo} alt="clintouch-logo" style={{ width: '140px', height: '130px' }} />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <InputGroup className={"no-border form-control-lg " + (this.state.usernameFocus ? "input-group-focus" : "")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input name="username" type="text" placeholder="Username"
                          value={username} 
                          onChange={this.handleChange}
                          onFocus={e => this.setState({ usernameFocus: true })}
                          onBlur={e => this.setState({ usernameFocus: false })}
                        />
                      </InputGroup>
                      <InputGroup
                        className={"no-border form-control-lg " + (this.state.passwordFocus ? "input-group-focus" : "")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input name = "password" type="password" placeholder="Password"
                          value={password} 
                          onChange={this.handleChange}
                          onFocus={e => this.setState({ passwordFocus: true })}
                          onBlur={e => this.setState({ passwordFocus: false })}
                        />
                      </InputGroup>
                      {badCredentialsError()}
                    </CardBody>
                    <CardFooter>
                      <Button block color="primary" size="lg" className="mb-3 btn-round" type="submit">
                        Get Started
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    );
  }
}
function mapState(state) {
  const { loggedIn } = state.authentication;
  return { loggedIn };
}

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
