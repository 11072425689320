import { genericConstants } from '../constants';
import { lookupService } from '../services';
import { alertActions } from '.';

export const lookupActions = {
    getForms,
    getFormsByType,
    getConcepts
};

function getForms() {
    return dispatch => {
        dispatch(request());
        lookupService.getAllForms()
            .then(
                response => {

                    const uresponse = Array.from(new Set(response.map(a => a.id)))
                        .map(id => {
                            return response.find(a => a.id === id)
                        })

                    dispatch(success(uresponse));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success(forms) { return { type: genericConstants.LOOKUPS_GET_SUCCESS_FORMS, forms } }

}

function getConcepts() {
    return dispatch => {
        dispatch(request());

        lookupService.getAllConcepts()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success(concepts) { return { type: genericConstants.LOOKUPS_GET_SUCCESS_CONCEPTS, concepts } }
}

function getFormsByType(type) {
    return dispatch => {
        dispatch(request());

        lookupService.getFormsByType(type)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success(formsbt) { return { type: genericConstants.LOOKUPS_GET_SUCCESS_FORMSBT, formsbt } }
}



function request() { return { type: genericConstants.LOOKUPS_GET_REQUEST } }
function failure(error) { return { type: genericConstants.LOOKUPS_GET_FAILURE, error } }

